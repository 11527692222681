import React, { useRef, useState, useEffect } from "react";
import { useProgress } from "@react-three/drei";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Language } from "./Components/Language";
import useIntro from "./../Store/Intro";
import { Tuto } from "./Tuto";
import CookieConsentOverlay from "./CookieConsentOverlay";

function Loader() {
  const width = "338";
  const { progress } = useProgress();
  const [loadingFinished, setLoadingFinished] = useIntro((state) => [
    state.loadingFinished,
    state.setLoadingFinished,
  ]);
  const [calc, setCalc] = useState(0);
  const [bar, setBar] = useState(0);

  useEffect(() => {
    /*   if (progress >= 96) {
      setLoadingFinished(true);
    }
    setCalc(progress.toFixed >= 20 ? progress.toFixed() - 50 : 0);*/
    if (bar < 100) {
      setBar((prevWidth) => prevWidth + 0.01);
    }
  }, [progress, bar]);
  return (
    <div className="center-div loadinglogo pb-12 w-[338px]">
      <img
        className="logointroback"
        src="./images/intro/tumi-logo_black.png"
        alt="logoblack"
      />
      <div
        className={`overflowlogofront`}
        style={{ width: `calc(338px * 0.01 * ${bar} )` }}
      >
        <img
          className="logointrofront"
          alt="logowhite"
          src="./images/intro/tumi-logo_white.png"
        />
      </div>
    </div>
  );
}
export function Intro({ children, ready }) {
  const [playvideo, setPlayVideo] = useIntro((state) => [
    state.playvideo,
    state.setPlayVideo,
  ]);

  const video = useRef();
  const [isHover, setIsHover] = useState(false);
  const [endedVideo, setEndedVideo] = useState(false);
  //  const [display, setDisplay] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const [setLastClicked] = useIntro((state) => [state.setLastClicked]);

  const [enterExperience, setEnterExperience] = useIntro((state) => [
    state.enterExperience,
    state.setEnterExperience,
  ]);

  const [introDisplay, setIntroDisplay] = useIntro((state) => [
    state.introDisplay,
    state.setIntroDisplay,
  ]);

  const [tutoDisplay, setTutoDisplay] = useIntro((state) => [
    state.tutoDisplay,
    state.setTutoDisplay,
  ]);

  const { progress } = useProgress();

  const { t } = useTranslation();

  const [progressBar, setProgressBar] = useState(0);
  useEffect(() => {
    if (progressBar < 100) {
      setProgressBar((prevWidth) => prevWidth + 0.01);
    }

    if (progressBar > 99) {
      setTutoDisplay(true);

      setTimeout(() => {
        setButtonShow(true);
      }, 1000);
    }
    /* if (progress >= 96) {
      setTutoDisplay(true);

      setTimeout(() => {
        setButtonShow(true);
      }, "1000");
    } else {
    }*/
  }, [progress, progressBar]);

  const handlePlayVideo = () => {
    setPlayVideo(true);
    // setPlayVideo(false);  A retirer pour la prod
    video.current.play();
    setTimeout(() => {
      setEnterExperience(true);
    }, 500);
  };

  const handleEndVideo = () => {
    setPlayVideo(false);
    setEndedVideo(true);
  };

  return (
    <>
      {children}

      {introDisplay && (
        <>
          {/* Splashscreen */}
          <CookieConsentOverlay />
          <div
            className={`fullscreen z-[50] bg ${ready ? "ready" : "notready"} ${
              enterExperience && "clicked"
            } ${isMobile ? "mobile" : "desktop"} `}
          >
            <div className="greybg">
              <a
                className="mentionlink absolute text-white bottom-1 left-1 sm:bottom-10 sm:left-10"
                href="https://tumi.com/terms.html"
                target="_blank"
                rel="noreferrer"
              >
                {t("legalnotice")}
              </a>
              {/*   <div className="intro-language">
                <Language />
              </div> */}
              <div className="flex flex-col w-full h-1/2 sm:h-1/3 justify-between  items-center	  absolute top-1/3  sm:top-1/2 left-1/2  transform -translate-x-1/2">
                <Loader />
                {/* {!display ? <Loader /> : <Loaded />}  */}
                {tutoDisplay && (
                  <>
                    <div
                      className={` textBottom ${
                        buttonShow ? "fade-in-animation" : "hide"
                      }`}
                    >
                      <div className="pb-12">
                        <button
                          onClick={handlePlayVideo}
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                          className={`enterbutton uppercase cursor-pointer	w-52 h-14	 transition-all	  ${
                            isHover ? "isHover" : "notHover"
                          }`}
                        >
                          {t("enterHere")}
                        </button>
                      </div>
                      <p className="text-lg">
                        Discover the TUMI universe and let yourself be guided
                        through this innovative immersive experience.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Video */}

          {!endedVideo && !isMobile && (
            <video
              playsInline
              onEnded={handleEndVideo}
              className={`absolute z-50 w-auto min-w-full min-h-full max-w-none ${
                playvideo ? "video" : "video hide-animation"
              }`}
              id="full-screenVideo"
              ref={video}
            >
              <source src="/videos/video_intro.mp4" type="video/mp4" />
            </video>
          )}
          {!endedVideo && isMobile && (
            <video
              playsInline
              onEnded={handleEndVideo}
              className={`absolute z-50 w-auto min-w-full min-h-full max-w-none ${
                playvideo ? "video" : "video hide-animation"
              }`}
              id="full-screenVideo"
              ref={video}
            >
              <source src="/videos/video_intro_mobile.mp4" type="video/mp4" />
            </video>
          )}
          {/* Explain screen */}

          {tutoDisplay && <Tuto />}
        </>
      )}

      <div className="ButtonMapDiv">
        <button
          className="buttonMap uppercase text-base  fixed   right-3.5   bottom-1 	 sm:bottom-10"
          onClick={() => {
            setIntroDisplay(true);

            setTutoDisplay(true);
            setTimeout(() => {
              setLastClicked(false);
            }, 100);
          }}
        >
          Tutorial
        </button>
      </div>
    </>
  );
}
