import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

export function Title() {
  useEffect(() => {});

  return (
    <>
      <h1 className={`title ${isMobile ? "mobile" : "desktop"}`}>Titre</h1>
    </>
  );
}
