import React, { useState, useEffect } from "react";
import CookieConsentForm from "./CookieConsentForm";
import { useTranslation } from "react-i18next";
import useIntro from "./../Store/Intro";

const CookieConsentOverlay = () => {
  const { t, i18n } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const [cookieDisplay, setCookieDisplay] = useIntro((state) => [
    state.cookieDisplay,
    state.setCookieDisplay,
  ]);

  useEffect(() => {
    const cookieStore = JSON.parse(localStorage.getItem("cookie"));
    if (cookieStore && cookieStore.cookie) {
      //HidePopup
      setCookieDisplay(true);
    } else {
      setCookieDisplay(false);
      // Show Popup
    }
  }, [cookieDisplay]);

  const toggleMoreInfo = () => {
    setShowForm(!showForm);
  };

  const acceptAll = async () => {
    localStorage.setItem("cookie", JSON.stringify({ set: true, cookie: true }));
    setCookieDisplay(true);
  };

  const denyAll = async () => {
    localStorage.setItem(
      "cookie",
      JSON.stringify({ set: false, cookie: true })
    );
    setCookieDisplay(true);
  };

  return (
    <div
      style={{ display: cookieDisplay ? "none" : "block" }}
      className={`cookie-consent-overlay  absolute top-0 left-0 w-full h-full bg-black z-[1000] bg-opacity-30	`}
    >
      {!showForm ? (
        <div className="cookie-consent-overlay__intro bg-white absolute left-1/2 top-1/2 w-full md:w-2/5 max-w-[800px] p-5  transform -translate-x-1/2  -translate-y-1/2">
          <h2 className="cookie-consent-overlay__title text-xl	text-center text-grey font-semibold tracking-widest">
            TUMI MAKES USE OF COOKIES
          </h2>
          <p className="cookie-consent-overlay__description my-8 text-sm text-center p-8 sm:p-0 ">
            We use cookies and similar technologies to personalize content and
            ads, to provide social media features and to analyse traffic. If you
            click on this website, you consent to our use of cookies, as
            described in our  
            <a
              href={
                i18n.language === "fr-FR"
                  ? `https://fr.tumi.com/privacy-and-security.html`
                  : `https://uk.tumi.com/privacy-and-security.html`
              }
              target="_blank"
              className="btn btn--secondary mb-5 text-sm underline"
            >
              privacy
            </a>{" "}
            and 
            <a
              href={
                i18n.language === "fr-FR"
                  ? `https://fr.tumi.com/cookies-policy.html`
                  : `https://uk.tumi.com/cookies-policy.html`
              }
              target="_blank"
              className="btn btn--secondary mb-5 text-sm underline"
            >
              cookies policy
            </a>
            .
          </p>

          <div className="cookie-consent-overlay__actions flex flex-col md:flex-row-reverse	 justify-center">
            <a
              href="#"
              className="btn btn--primary bg-red text-white m-2.5 py-2.5 px-8 font-bold text-xl hover:text-white hover:bg-darkred"
              data-track="yes"
              data-category="cookie settings"
              data-action="accept"
              onClick={(e) => {
                e.preventDefault();
                acceptAll();
              }}
            >
              I accept
            </a>
            <a
              href="#"
              className="btn btn--secondary  m-2.5 py-2.5 px-8 font-bold border-2 border-black"
              onClick={(e) => {
                e.preventDefault();
                denyAll();
              }}
            >
              REJECT ALL
            </a>
            <a
              href="#"
              className="btn btn--secondary  m-2.5  py-2.5 px-8 font-bold border-2 border-black"
              onClick={(e) => {
                e.preventDefault();
                toggleMoreInfo();
              }}
            >
              MORE INFO
            </a>
          </div>
        </div>
      ) : (
        <div className="cookie-consent-overlay__intro">
          <h2 className="cookie-consent-overlay__title">
            YOUR CHOICES REGARDING COOKIES ON THIS WEBSITE
          </h2>
          <CookieConsentForm />
        </div>
      )}
    </div>
  );
};

export default CookieConsentOverlay;
