import React, { useRef, useState } from "react";
import { Environment, SoftShadows } from "@react-three/drei";
import * as THREE from "three";
import { isMobile } from "react-device-detect";

export function Light(props) {
  const [target2] = useState(() => new THREE.Object3D());
  const [target3] = useState(() => new THREE.Object3D());

  return (
    <>
      <ambientLight intensity={0.7} />
      <SoftShadows />

      <Environment
        background={true}
        files={`./images/environment/HDRI_v6.hdr`}
      />
      <Environment background={false} files={`./images/environment/test.hdr`} />
    </>
  );
}
