import { create } from "zustand";

const useCartStore = create((set) => ({
  cart: [],
  addToCart: (element) =>
    set((state) => {
      const isPresent = state.cart.find((bag) => bag.item === element.item);
      if (!isPresent) {
        return {
          ...state,
          cart: [...state.cart, { ...element, quantity: 1 }],
        };
      }

      const updatedCart = state.cart.map((bag) =>
        bag.item === element.item
          ? {
              ...bag,
              quantity: bag.quantity + 1,
            }
          : bag
      );

      return {
        ...state,
        cart: updatedCart,
      };
    }),
  emptyCart: () => set({ cart: [] }),
  removeFromCart: (element) =>
    set((state) => {
      const isPresent = state.cart.findIndex(
        (bag) => bag.item === element.item
      );
      if (isPresent === -1) {
        return {
          ...state,
        };
      }

      const updatedCart = state.cart
        .map((bag) =>
          bag.item === element.item
            ? { ...bag, quantity: Math.max(bag.quantity - 1, 0) }
            : bag
        )
        .filter((bag) => bag.quantity);
      return {
        ...state,
        cart: updatedCart,
      };
    }),
}));

export default useCartStore;
