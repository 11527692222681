import React from "react";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

export function Montgolfiere02(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF("./models/hotairballoon.glb");
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.set(
      Math.sin(t) * 0.01,
      Math.sin(t + 2) * 0.015,
      Math.sin(t + 3) * 0.005
    );
  });
  return (
    <primitive
      object={nodes.montgolfiere02001}
      position={[-4, 2, -2]}
      castShadow
      receiveShadow
      ref={ref}
    />
  );
}
