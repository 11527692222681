import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "./Dropdown";
import useCurrencySelected from "./../../Store/Currency";

export function Language() {
  const [currencySelected, setCurrencySelected] = useCurrencySelected(
    (state) => [state.currencySelected, state.setCurrencySelected]
  );

  const { i18n } = useTranslation();

  const language = i18n.translator.language;
  useEffect(() => {}, [language]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleMenuOne = () => {
    setCurrencySelected("€");
    changeLanguage("en-FR");
  };

  const handleMenuTwo = () => {
    setCurrencySelected("£");
    changeLanguage("en-GB");
  };
  /*
  const handleMenuThree = () => {
    setSelectedButton("es");
    changeLanguage("es");
  };
*/
  return (
    <Dropdown
      language={language}
      trigger={
        <button className="main-button font-normal uppercase z-[46]">
          {currencySelected}
        </button>
      }
      menu={[
        <button onClick={handleMenuOne}>€</button>,
        <button onClick={handleMenuTwo}>£</button>,
      ]}
    />
  );
}
