import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useCartStore from "./../Store/Cart";
import useCameraStore from "./../Store/Camera";

export function Cart({ children, ready }) {
  const cart = useCartStore((state) => state.cart);
  const setPopupOpen = useCameraStore((state) => state.setPopupOpen);

  const removeFromCart = useCartStore((state) => state.removeFromCart);
  const { t, i18n } = useTranslation();
  const [showCart, setShowCart] = useState(false);
  const fallbackLanguage = i18n.options.fallbackLng[0];

  useEffect(() => {
    if (cart && cart.length) {
      setPopupOpen(true);
      setShowCart(true);
    }
  }, [cart, setPopupOpen]);

  const removingProduct = function (selectedData) {
    removeFromCart(selectedData);
  };

  const toggleCart = function (state) {
    setPopupOpen(state);
    setShowCart(state);
  };

  const displayName = useMemo(() => {
    let text = "[";
    cart.map((element) => {
      return (text +=
        `{"pid":"` + element.reference + `","qty":` + element.quantity + `},`);
    });
    text = text.substring(0, text.length - 1);
    text += "]";
    return encodeURIComponent(text);
  }, [cart]);

  const displayTotal = useMemo(() => {
    let total = Number(0);
    cart.map((element) => {
      return (total +=
        Number(element.price[i18n.language]) * Number(element.quantity));
    });
    return total;
  }, [cart, i18n.language]);

  const displayTotalInCart = useMemo(() => {
    let total = Number(0);
    cart.map((element) => {
      return (total += Number(1) * Number(element.quantity));
    });
    return total > 0 ? total : null;
  }, [cart]);

  return (
    <>
      <a href="https://www.tumi.com" target="_blank">
        <picture>
          <source srcSet="/images/tumi-logo.png" media="(min-width: 600px)" />
          <img
            className={`absolute top-3.5 left-3.5  w-8 sm:w-20`}
            src="images/icons/tumi-logo-mobile.png"
            alt="Tumi logo"
          />
        </picture>
      </a>
      <div
        className="absolute top-3.5 right-3.5 flex content-center cartButton"
        onClick={() => toggleCart(true)}
      >
        <img className=" w-8" src="images/icons/cart_r.png" alt="Cart button" />
        <button
          className={`text-2xl  text-center self-center h-6 ${
            displayTotalInCart ? "w-6" : ""
          }`}
        >
          {displayTotalInCart}
        </button>
      </div>
      <div
        className={`cartList flex  flex-col px-4 fixed right-0 top-0 h-full duration-1000 transition-all	z-[51] w-screen sm:w-96  ${
          showCart ? "" : "translate-x-full"
        }`}
      >
        <h3 className="text-center uppercase  py-6 m-6 font-bold text-base border-b-2 border-lightgrey">
          {t("Wishlist")}
        </h3>
        <button
          className="close absolute right-5 top-4 text-4xl"
          onClick={() => toggleCart(false)}
        >
          ×
        </button>

        <ul className="mx-2 grow  px-2">
          {cart.map((element, i) => {
            // Affichage
            return (
              <li className="cartElement" key={i}>
                <div className="px-3 w-2/5 float-left	">
                  <img
                    className="max-w-full w-20 block"
                    alt={
                      element.title[i18n.language] ||
                      element.title[fallbackLanguage]
                    }
                    src={element.image ? element.image : element.images[0]}
                  />
                </div>
                <div className=" w-3/5  float-right mt-2.5 mb-1.5">
                  <h4 className="font-normal	text-sm	 ">
                    {element.title[i18n.language] ||
                      element.title[fallbackLanguage]}
                  </h4>
                </div>
                <div className=" w-3/5 text-[10px] font-bold text-darkgrey uppercase float-right mb-1.5">
                  {element.category[i18n.language] ||
                    element.category[fallbackLanguage]}
                </div>
                <div className=" w-3/5 text-sm float-right ">
                  {i18n.language === "en-GB" && <span>£</span>}
                  {element.price[i18n.language] ||
                    element.price[fallbackLanguage]}
                  {i18n.language === "en-FR" && <span>€</span>}
                  {i18n.language === "fr-FR" && <span>€</span>}
                  {i18n.language === "en" && <span>€</span>}
                </div>
                <div className=" w-3/5 text-darkgrey text-xs mt-4  float-right">
                  {t("color")} :{" "}
                  {element.color[i18n.language] ||
                    element.color[fallbackLanguage]}
                </div>
                <div className=" w-3/5 text-darkgrey my-1 text-xs  mt-1.5 float-right">
                  {t("quantity")} : {element.quantity}
                </div>
                <div className="w-3/5 text-sm float-right	 mt-0.5 mb-1.5">
                  <button
                    className="underline cursor-pointer	hover:text-red"
                    onClick={() => removingProduct(element)}
                  >
                    {t("remove")}
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="flex text-sm justify-between uppercase font-bold border-t-2	pt-2 border-lightgrey">
          {t("total")}
          <span>
            {i18n.language === "en-GB" && <span>£</span>}
            {displayTotal}
            {i18n.language === "en-FR" && <span>€</span>}
            {i18n.language === "fr-FR" && <span>€</span>}
            {i18n.language === "en" && <span>€</span>}
          </span>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            `https://tumi.com/on/demandware.store/Sites-tumi-Site/` +
            i18n.language.replace(/-/g, "_") +
            `/CartUtils-RestoreCart?json=` +
            displayName
          }
          className=" text-center text-xl	font-bold uppercase h-12 bottom-1 my-5 w-full py-3  bg-red hover:bg-darkred  text-white	hover:text-white"
        >
          {t("command")}
        </a>
      </div>
    </>
  );
}
