import React from "react";
import { useTranslation } from "react-i18next";
import useIntro from "./../Store/Intro";

const CookieConsentForm = () => {
  const { t, i18n } = useTranslation();
  const [setCookieDisplay] = useIntro((state) => [state.setCookieDisplay]);
  const updateConsent = async () => {
    localStorage.setItem("cookie", JSON.stringify({ set: true, cookie: true }));
    setCookieDisplay(true);
  };

  return (
    <div className="cookie-consent-overlay__intro bg-white absolute left-1/2 top-1/2 w-full md:w-2/5 p-8 h-full md:h-auto overflow-scroll md:overflow-hidden	 transform -translate-x-1/2  -translate-y-1/2">
      <h3 className="cookie-consent-overlay__title text-xl	text-center text-grey font-semibold tracking-widest">
        YOUR CHOICES REGARDING COOKIES ON THIS WEBSITE
      </h3>
      <div className="cookie-consent-form__list text-grey">
        <div className="cookie-consent-form_groups">
          <div className="cookie-consent-form_group text-sm p-4 my-5 bg-[#f5f5f5]">
            <div className="form-row label-inline checkbox consent-group_checkbox">
              <input
                className="input-checkbox "
                type="checkbox"
                disabled
                checked
              />
              <label className="ml-4 inline-block" htmlFor="essential">
                Essential Cookies
              </label>
            </div>
            <div className="form-row consent-group_explanation mt-3 cursor-pointer	">
              <p className="text-xs">
                Essential cookies enable you to navigate the Website and to use
                its services and features. Without these absolute necessary
                cookies, the Website will not perform as smoothly for you as we
                would like it to and we may not be able to provide the website
                or certain services or features.
              </p>
            </div>
          </div>
          <div className="cookie-consent-form_group text-sm p-4 my-5 bg-[#f5f5f5]">
            <div className="form-row label-inline checkbox consent-group_checkbox">
              <input
                className="input-checkbox cursor-pointer"
                type="checkbox"
                name="analytics"
                id="analytics"
              />
              <label
                htmlFor="analytics"
                className="ml-4 inline-block cursor-pointer"
              >
                Analytics Cookies
              </label>
            </div>
            <div className="form-row consent-group_explanation mt-3">
              <label htmlFor="analytics">
                <p className="text-xs  cursor-pointer">
                  Analytics cookies collect information about your use of the
                  Website and enable us to improve the way it works. Analytics
                  cookies such as used by Hotjar show us which are the most
                  frequently visited pages on the Website, help us record any
                  difficulties you have with the Website, and show us whether
                  our advertising is effective or not. This allows us to see the
                  overall patterns of usage on the Website, rather than the
                  usage of a single person. We use the information in aggregate
                  form to analyse Website traffic and perform statistical
                  analyses of the collective characteristics and behaviour of
                  our visitors by measuring demographics and interests regarding
                  specific areas of the Website, but we do not examine this
                  information for individually identifying information.
                </p>
              </label>
            </div>
          </div>
          <div className="cookie-consent-form_group text-sm p-4 my-5 bg-[#f5f5f5]">
            <div className="form-row label-inline checkbox consent-group_checkbox">
              <input
                className="input-checkbox cursor-pointer"
                type="checkbox"
                name="marketing"
                id="marketing"
                value="true"
              />
              <label
                htmlFor="marketing"
                className="ml-4 inline-block cursor-pointer"
              >
                Targeting and advertising Cookies or similar technologies
              </label>
            </div>
            <div className="form-row consent-group_explanation mt-3 ">
              <label htmlFor="marketing">
                <p className="text-xs cursor-pointer">
                  These are mainly used to deliver adverts more relevant to your
                  personal interests. This is called “retargeting”. This means
                  cookies, pixels or plugins that are usually placed by
                  carefully selected advertising networks (e.g. banner
                  advertising) based on your browsing behaviour and the website
                  and location, may be offered to you when you visit other
                  websites participating in the advertising network.
                </p>
              </label>
            </div>
          </div>
        </div>
        <a
          href={
            i18n.language === "fr-FR"
              ? `https://fr.tumi.com/cookies-policy.html`
              : `https://uk.tumi.com/cookies-policy.html`
          }
          target="_blank"
          className="btn btn--secondary mb-5 text-sm underline"
        >
          More Info
        </a>
      </div>
      <div className="cookie-consent-form__actions flex flex-row">
        <a
          href="#"
          className="btn btn--primary bg-red text-white mt-2.5 py-2.5 px-8 font-bold text-xl hover:text-white hover:bg-darkred"
          onClick={(e) => {
            e.preventDefault();
            updateConsent();
          }}
        >
          Submit preferences
        </a>
      </div>
    </div>
  );
};

export default CookieConsentForm;
