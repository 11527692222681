import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { isMobile } from "react-device-detect";
import { Train } from "../Objects/Train";

export function MainScene(props) {
  const group = useRef();
  const { scene } = useGLTF("./models/tumiv20.glb");

  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={scene} />
    </group>
  );
}

useGLTF.preload("./models/tumiv20.glb");
