import * as THREE from "three";
import { useMemo, useRef } from "react";
import { applyProps, useFrame } from "@react-three/fiber";
import { useGLTF, MeshTransmissionMaterial } from "@react-three/drei";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";

export function LogoTumi(props) {
  const { scene, nodes, materials } = useGLTF("./models/logoTumi.glb");
  const ref = useRef();

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.set(0, t, 0);
  });

  applyProps(nodes.logo.material, {
    color: "#666",
    metalness: 1,
    roughness: 0.5,
    ambientIntensity: 0.2,
  });

  return (
    <mesh
      geometry={nodes.logo.geometry}
      //   position={nodes.logo.position}
      material={nodes.logo.material}
      scale={0.7}
      position={[0, -0.7, 0]}
      ref={ref}
    >
      {/* {!isMobile && ( 
        <MeshTransmissionMaterial
          anisotropy={0.5}
          transmission={0.7}
          distortion={4}
          distortionScale={0.2}
          ior={0.9}
          roughness={0.2}
          color="#555"
          gcolor="#000"
        />
      )} */}
    </mesh>
  );
}
