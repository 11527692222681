import React from "react";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

export function Montgolfiere(props) {
  const { nodes } = useGLTF("./models/hotairballoon.glb");
  const ref = useRef();
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    // const look = ref.current.lookAt();

    ref.current.rotation.set(
      Math.sin(t) * 0.05,
      Math.sin(t) * 0.003,
      Math.sin(t) * 0.03
    );
    ref.current.children[0].material.color.set(0xffffff);
  });
  return (
    <primitive
      object={nodes.montgolfiere01001}
      position={[5, 2, -5]}
      castShadow
      receiveShadow
      ref={ref}
    />
  );
}
