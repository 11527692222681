/* eslint-disable no-unused-expressions */
import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  memo,
  useState,
} from "react";
import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  Html,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

import { isMobile } from "react-device-detect";
import useCameraStore from "../../Store/Camera";
import { useCameraPosition } from "../../Store/Camera";
import { useCameraLerpInterpolation } from "../../Store/Camera";

function Sender({ ...props }) {
  const [setUpdateTime] = useCameraStore((state) => [state.updateTime]);

  return (
    <>
      <Html wrapperClass="upperTitle" as="div" prepend>
        <div className={`absolute bottom-10 w-2/6	`}>
          <div>
            Internation carry-on <br />
            19 Degree Aluminum
            <button>Bouton 1</button>
          </div>
        </div>
      </Html>
    </>
  );
}

export const NewCamera = memo(() => {
  const titleRef = useRef();
  const bagNameTitle = useRef();
  const bagCategoryName = useRef();
  const bagLink = useRef();
  const bagNameRef = useRef();

  //  const [mapMoving, setMapMoving] = React.useState(false);
  let mapMoving = 0;
  //zustand store
  const [cameraPosition, setCameraPosition] = useCameraPosition((state) => [
    state.cameraPosition,
    state.setCameraPosition,
  ]);
  const [lerpInterpolation] = useCameraLerpInterpolation((state) => [
    state.lerpInterpolation,
  ]);

  let lerpO = 0.05;

  useEffect(() => {
    mapMoving = 1;
    handleCallback(cameraPosition);
    lerpO = lerpInterpolation;
    setTimeout(() => {
      lerpO = 0.05;
      mapMoving = 0;
    }, 250);
  }, [cameraPosition]);

  // const popupOpen = useRef(useCameraStore.getState().popupOpen);
  //  const time = useCameraStore((state) => state.time);
  // const updateTime = useCameraStore((state) => state.updateTime);

  if ("maxTouchPoints" in navigator) {
    isMobile = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    isMobile = navigator.msMaxTouchPoints > 0;
  } else {
    let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      isMobile = !!mQ.matches;
    } else if ("orientation" in window) {
      isMobile = true;
    } else {
      isMobile =
        /Android|Windows Phone|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
    }
  }

  /* SCROLL AMOUNTS >>> GREATER = QUICKER */
  const verticalScrollAmt = 5;
  const horizontalScrollAmt = 5;

  /* GLOBAL VARIABLES FOR UPDATES */
  const mouse = new THREE.Vector2();
  const fullLength = 60000;
  let time = 0;
  let lerpTime = 0;
  let animLerp = 0;
  let ad = 0;
  let ws = 0;
  let adL = 0;
  let wsL = 0;
  let moving = false;
  let mouseDown = false;
  const mouseStart = new THREE.Vector2();
  const mouseDelta = new THREE.Vector2();
  let firstTouch = null;
  let swipingH = false;
  let swipingV = false;
  let clamp = 0;

  function handleCallback(childData) {
    time = childData;
    animLerp = percentage * time;
  }
  /* useRef's AND OBJECTS CREATION */
  const cameraRef = useRef();

  const { scene, nodes, animations } = useGLTF(
    "./models/camera/CamPathBaked.glb"
  );
  const { actions } = useAnimations(animations, scene);

  const duration = actions["clip"]._clip.duration;
  const percentage = duration / fullLength;

  let camPos = nodes.camPos;
  let camLook = nodes.camLook;

  const dummy = useRef();
  const dummyLook = useRef();
  const dummyLerp = new THREE.Group();

  dummyLerp.isCamera = true;
  dummyLerp.position.copy(camPos.position);
  dummyLerp.lookAt(camLook.position);

  /* useEffects */
  useLayoutEffect(() => {
    dummyLook.current.position.setFromSphericalCoords(1.5, -Math.PI / 2, 0);
    dummyLook.current.lookAt(dummy.current.position);
    dummy.current.position.copy(camPos.position);
    dummy.current.lookAt(camLook.position);
  });

  useEffect(() => {
    actions["clip"].play().paused = true;
    /* MOBILE SWIPE EVENTS */
    if (isMobile) {
      document
        .getElementById("capturePointer")
        .addEventListener("touchstart", handleTouchStart, false);
      document
        .getElementById("capturePointer")
        .addEventListener("touchmove", handleTouchMove, false);
      document
        .getElementById("capturePointer")
        .addEventListener("touchend", handleTouchEnd, false);
    } else {
      /* MOUSE EVENTS */
      document
        .getElementById("capturePointer")
        .addEventListener("mousedown", handleMouseDown, false);
      document
        .getElementById("capturePointer")
        .addEventListener("mousemove", handleMouseMove, false);
      document
        .getElementById("capturePointer")
        .addEventListener("mouseup", handleMouseUp, false);
      document.addEventListener("mousewheel", handleMouseWheel, false);
    }
    if (titleRef.current) {
      titleRef.current.innerHTML = "Welcome";
    }
    if (bagNameRef.current) {
      bagNameRef.current.className = "hide-animation";
    }
  }, [cameraPosition]);

  function checkTitleTime(scrollTime) {
    if (scrollTime >= 55000) {
      titleRef.current.className =
        "title  text-2xl font-semibold hide-animation";
    } else if (scrollTime >= 38000) {
      titleRef.current.innerHTML = "Sustainability";
      titleRef.current.className = " title  text-2xl font-semibold fade-in";
    } else if (scrollTime >= 37000) {
      titleRef.current.className =
        "title  text-2xl font-semibold hide-animation";
    } else if (scrollTime >= 30000) {
      titleRef.current.innerHTML = "Tegra Lite";
      titleRef.current.className = " title  text-2xl font-semibold fade-in";
    } else if (scrollTime >= 29000) {
      titleRef.current.className =
        "title  text-2xl font-semibold hide-animation";
    } else if (scrollTime >= 20200) {
      titleRef.current.innerHTML = "Alpha Bravo";
      titleRef.current.className = " title  text-2xl font-semibold fade-in";
    } else if (scrollTime >= 18200) {
      titleRef.current.className = "title hide-animation";
    } else if (scrollTime >= 14500) {
      titleRef.current.innerHTML = "19 Degree Aluminum";
      titleRef.current.className = " title  text-2xl font-semibold fade-in";
    } else if (scrollTime >= 13000) {
      titleRef.current.className =
        "title  text-2xl font-semibold hide-animation";
    } else if (scrollTime >= 5000) {
      titleRef.current.innerHTML = "McLaren";
      titleRef.current.className = " title  text-2xl font-semibold fade-in";
    } else if (scrollTime >= 4000) {
      titleRef.current.className =
        "title   text-2xl font-semibold hide-animation";
    } else {
      titleRef.current.className = " title  text-2xl font-semibold fade-in";
      titleRef.current.innerHTML = "Welcome";
    }

    if (!mapMoving) {
      if (actions["clip"].time >= 25.83) {
        lerpO = 0.05;
      } else if (actions["clip"].time >= 25.63) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 25.35) {
        lerpO = 0.01;
      } else if (actions["clip"].time >= 25) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 19.57) {
        lerpO = 0.05;
      } else if (actions["clip"].time >= 18.77) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 17.28) {
        lerpO = 0.02;
      } else if (actions["clip"].time >= 16.88) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 15.8) {
        lerpO = 0.05;
      } else if (actions["clip"].time >= 14.88) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 13.21) {
        lerpO = 0.02;
      } else if (actions["clip"].time >= 12.9) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 11.02) {
        lerpO = 0.05;
      } else if (actions["clip"].time >= 9.88) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 8.6) {
        lerpO = 0.02;
      } else if (actions["clip"].time >= 8.3) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 6.9) {
        lerpO = 0.05;
      } else if (actions["clip"].time >= 5.9) {
        lerpO = 0.035;
      } else if (actions["clip"].time >= 4.18) {
        lerpO = 0.02;
      } else if (actions["clip"].time >= 3.98) {
        lerpO = 0.035;
      } else {
        lerpO = 0.05;
      }
    }
  }

  function checkBagTime(scrollTime) {
    if (actions["clip"].time >= 25.6) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 25.2) {
      bagNameTitle.current.innerHTML = "International Expandable";
      bagCategoryName.current.innerHTML = "19 Degree";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 18.8) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 18.4) {
      bagNameTitle.current.innerHTML = "International Expandable ";
      bagCategoryName.current.innerHTML = "Tegra Lite";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 17.9) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 17.4) {
      bagNameTitle.current.innerHTML = "International Front Pocket Expandable";
      bagCategoryName.current.innerHTML = "Tegra Lite";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 14.85) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 14.7) {
      bagNameTitle.current.innerHTML = "Navigation Backpack";
      bagCategoryName.current.innerHTML = "Alpha Bravo";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 14.5) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 14.1) {
      bagNameTitle.current.innerHTML = "Classified Waist Pack";
      bagCategoryName.current.innerHTML = "Alpha Bravo";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 13.5) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 13.1) {
      bagNameTitle.current.innerHTML = "Mason Duffel";
      bagCategoryName.current.innerHTML = "Alpha Bravo";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 10.1) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 9.7) {
      bagNameTitle.current.innerHTML = "International Expandable Carry-on";
      bagCategoryName.current.innerHTML = "19 Degree Aluminum";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 9.2) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 8.8) {
      bagNameTitle.current.innerHTML = "International Carry-on";
      bagCategoryName.current.innerHTML = "19 Degree Aluminum";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 5.9) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 5.6) {
      bagNameTitle.current.innerHTML = "Quantum Duffel";
      bagCategoryName.current.innerHTML = "McLaren";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 5.5) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 5.27) {
      bagNameTitle.current.innerHTML = "Aero International Expandable ";
      bagCategoryName.current.innerHTML = "McLaren";
      bagNameRef.current.className = " fade-in-animation";
    } else if (actions["clip"].time >= 4.86) {
      bagNameRef.current.className = "hide-animation";
    } else if (actions["clip"].time >= 4.37) {
      bagNameRef.current.className = " fade-in-animation";
      bagNameTitle.current.innerHTML = "Velocity Backpack";
      bagCategoryName.current.innerHTML = "McLaren";
    } else if (bagNameRef.current) {
      bagNameRef.current.className = "hide-animation";
    }
  }

  useFrame(() => {
    if (titleRef.current) {
      checkTitleTime(time);
      checkBagTime(time);
    }
    calculateLerps();
  });

  /* MOUSE EVENTS */
  function handleMouseDown(e) {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
    mouseStart.set(e.clientX, e.clientY);
    moving = false;
    mouseDown = true;
  }

  function handleMouseMove(e) {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

    if (mouseDown) {
      mouseDelta.x = e.clientX - mouseStart.x;
      mouseStart.x = e.clientX;
      mouseDelta.y = e.clientY - mouseStart.y;
      mouseStart.y = e.clientY;

      ad += Math.sin(mouseDelta.x / (5000 / horizontalScrollAmt));
      ws += Math.sin(mouseDelta.y / (5000 / horizontalScrollAmt));

      if (ws >= Math.PI / 2 - 0.01) {
        ws = Math.PI / 2 - 0.01;
      }
      if (ws <= -Math.PI / 2 + 0.01) {
        ws = -Math.PI / 2 + 0.01;
      }

      if (ad > Math.PI) {
        ad = -Math.PI;
      }
      if (ad < -Math.PI) {
        ad = Math.PI;
      }

      dummyLook.current.position.setFromSphericalCoords(
        1.5,
        -Math.PI / 2 + ws,
        ad
      );
      dummyLook.current.lookAt(dummy.current.position);
    }
  }

  function handleMouseUp(e) {
    mouseDown = false;
  }

  function handleMouseWheel(e) {
    moving = true;

    if (!mouseDown) {
      time += e.deltaY * (verticalScrollAmt / 4);
      animLerp = percentage * time;
    }

    if (lerpTime > duration) {
      lerpTime = 0;
      time = 0;
      animLerp = 0;
    }

    if (lerpTime < 0) {
      animLerp = duration;
      time = fullLength;
      lerpTime = duration;
    }

    if (ad >= 0) {
      ad -= 0.1;
      if (ad < 0) {
        ad = 0;
      }
    }
    if (ad <= 0) {
      ad += 0.1;
      if (ad > 0) {
        ad = 0;
      }
    }

    if (ws >= 0) {
      ws -= 0.1;
      if (ws < 0) {
        ws = 0;
      }
    }
    if (ws <= 0) {
      ws += 0.1;
      if (ws > 0) {
        ws = 0;
      }
    }
  }

  /* MOBILE SWIPE EVENTS */
  function handleTouchStart(e) {
    //   e.preventDefault();
    firstTouch = e.touches[0] || e.originalEvent.touches[0];
    mouseStart.set(firstTouch.clientX, firstTouch.clientY);
  }

  function handleTouchMove(e) {
    if (!mouseStart.x || !mouseStart.y) {
      return;
    }

    mouse.x = (e.targetTouches[0].pageX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.targetTouches[0].pageY / window.innerHeight) * 2 + 1;

    var xUp = e.touches[0].clientX;
    var yUp = e.touches[0].clientY;

    var xDiff = mouseStart.x - xUp;
    var yDiff = mouseStart.y - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff) && !swipingV) {
      swipingH = true;
    }
    if (Math.abs(xDiff) < Math.abs(yDiff) && !swipingH) {
      swipingV = true;
    }
    if (swipingH) {
      moving = false;

      mouseDelta.x = e.touches[0].clientX - mouseStart.x;
      mouseStart.x = e.touches[0].clientX;

      ad += Math.sin(mouseDelta.x / (2000 / horizontalScrollAmt));

      if (ad > Math.PI) {
        ad = -Math.PI;
      }
      if (ad < -Math.PI) {
        ad = Math.PI;
      }

      dummyLook.current.position.setFromSphericalCoords(
        1.5,
        -Math.PI / 2 + ws,
        ad
      );
      dummyLook.current.lookAt(dummy.current.position);
    }

    if (swipingV) {
      moving = true;

      mouseDelta.y = e.touches[0].clientY - mouseStart.y;
      mouseStart.y = e.touches[0].clientY;

      if (mouseDelta.y > 0) {
        time += verticalScrollAmt * mouseDelta.y;
      } else if (mouseDelta.y < 0) {
        time -= verticalScrollAmt * -mouseDelta.y;
      }

      if (lerpTime > duration) {
        lerpTime = 0;
        time = 0;
        animLerp = 0;
      }

      if (lerpTime < 0) {
        animLerp = duration;
        time = fullLength;
        lerpTime = duration;
      }

      clamp = THREE.MathUtils.clamp(mouseDelta.y, -5, 5);

      if (ad >= 0) {
        mouseDelta.y > 0 ? (ad -= 0.07 * clamp) : (ad -= 0.07 * -clamp);
        if (ad < 0) {
          ad = 0;
        }
      }
      if (ad <= 0) {
        mouseDelta.y > 0 ? (ad += 0.07 * clamp) : (ad += 0.07 * -clamp);
        if (ad > 0) {
          ad = 0;
        }
      }
    }
  }

  function handleTouchEnd() {
    mouseDown = false;
    swipingH = false;
    swipingV = false;
  }

  // MAIN ANIMATION UPDATE //
  function calculateLerps() {
    animLerp = percentage * time;

    //Changing lerpInterpolation for *teleportation* via map
    lerpTime = THREE.MathUtils.lerp(lerpTime, animLerp, lerpO);

    adL = THREE.MathUtils.lerp(adL, ad, 0.08);

    wsL = THREE.MathUtils.lerp(wsL, ws, 0.08);

    actions["clip"].time = lerpTime;

    dummyLerp.position.copy(camPos.position);
    dummyLerp.lookAt(camLook.position);
    dummy.current.position.copy(camPos.position);
    dummy.current.quaternion.slerp(dummyLerp.quaternion, 0.2);

    if (moving) {
      dummyLook.current.position.setFromSphericalCoords(
        1.5,
        -Math.PI / 2 + wsL,
        adL
      );
    }

    dummyLook.current.lookAt(dummy.current.position);
    cameraRef.current.quaternion.slerp(dummyLook.current.quaternion, 0.05);
  }

  // const [map, setMap] = useState(true);
  //  let map = true;

  /* RETURN COMPONENT */
  return (
    <>
      <Html wrapperClass="upperTitle" as="div" prepend>
        <h1
          className={`title text-2xl font-semibold uppercase`}
          ref={titleRef}
        ></h1>
      </Html>
      <Html wrapperClass="downTitle" as="div" prepend>
        <div
          className={`absolute  bottom-10 md:bottom-20 w-2/3 max-w-screen-md md:w-auto 	 pb-10`}
        >
          <div className="hide-animation" ref={bagNameRef}>
            <div className="bg-white p-3 pr-10">
              <p
                className={`text-base	 sm:text-2xl font-semibold uppercase`}
                ref={bagNameTitle}
              />
              <p className="text-sm sm:text-base" ref={bagCategoryName}></p>
            </div>
          </div>
        </div>
      </Html>

      <group ref={dummy} isCamera={true}>
        <group ref={dummyLook}></group>
        <PerspectiveCamera
          ref={cameraRef}
          fov={isMobile ? 100 : 50}
          makeDefault={true}
        />
      </group>
    </>
  );
});
