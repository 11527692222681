import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useCameraPosition, useMap } from "../Store/Camera";
import { useCameraLerpInterpolation } from "../Store/Camera";
import { isMobile } from "react-device-detect";

export const Map = memo(function (props) {
  const { t } = useTranslation();

  //position zustand
  const [cameraPosition, setCameraPosition] = useCameraPosition((state) => [
    state.cameraPosition,
    state.setCameraPosition,
  ]);

  const [setLerpInterpolation] = useCameraLerpInterpolation((state) => [
    state.setLerpInterpolation,
  ]);

  const [map, setMap] = useMap((state) => [state.map, state.setMap]);

  const [plane, setPlane] = useState(false);
  function moveTo(distance) {
    if (cameraPosition !== distance) {
      setLerpInterpolation(0.99);
      setCameraPosition(distance);
    } else {
      setLerpInterpolation(0.99);
      setCameraPosition(distance + 1);
    }
    setPlane(true);
    //setMap(false);

    //this is resetting the camera to the beginning of the map.

    setTimeout(() => {
      setMap(false);
      setPlane(false);
    }, 500);
  }

  return (
    <>
      <div className="ButtonMapDiv">
        <button
          className="buttonMap uppercase text-base  fixed   left-1/2 transform -translate-x-1/2   bottom-1 	 sm:bottom-10"
          onClick={() => setMap(true)}
        >
          {t("map")}
        </button>
      </div>
      <div
        className={`ImageMap absolute z-10 w-screen smooth-show ${
          map ? "opacity-100 h-screen showing " : "opacity-0	 h-0"
        }`}
      >
        {!isMobile && (
          <>
            <img
              className="logointroback absolute top-5 left-3.5 w-8 sm:w-20 pointer-events-none	"
              src="./images/intro/tumi-logo_black.png"
              alt="logoblack"
            />
            <div className={`zoomingTwerk ${map ? "h-5/6" : "h-0"}`}>
              {map && (
                <>
                  <img
                    src="./images/map.webp"
                    alt="map"
                    className="imgOfTheMap"
                  />
                  {plane && (
                    <svg
                      className="svg-calLoader absolute z-30 top-1/3 left-80 "
                      xmlns="http://www.w3.org/2000/svg"
                      width="230"
                      height="230"
                    >
                      <path
                        className="cal-loader__path"
                        d="M86.429 40c63.616-20.04 101.511 25.08 107.265 61.93 6.487 41.54-18.593 76.99-50.6 87.643-59.46 19.791-101.262-23.577-107.142-62.616C29.398 83.441 59.945 48.343 86.43 40z"
                        fill="none"
                        stroke="#0099cc"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="10 10 10 10 10 10 10 432"
                        strokeDashoffset="77"
                      />
                      <path
                        className="cal-loader__plane "
                        d="M141.493 37.93c-1.087-.927-2.942-2.002-4.32-2.501-2.259-.824-3.252-.955-9.293-1.172-4.017-.146-5.197-.23-5.47-.37-.766-.407-1.526-1.448-7.114-9.773-4.8-7.145-5.344-7.914-6.327-8.976-1.214-1.306-1.396-1.378-3.79-1.473-1.036-.04-2-.043-2.153-.002-.353.1-.87.586-1 .952-.139.399-.076.71.431 2.22.241.72 1.029 3.386 1.742 5.918 1.644 5.844 2.378 8.343 2.863 9.705.206.601.33 1.1.275 1.125-.24.097-10.56 1.066-11.014 1.032a3.532 3.532 0 0 1-1.002-.276l-.487-.246-2.044-2.613c-2.234-2.87-2.228-2.864-3.35-3.309-.717-.287-2.82-.386-3.276-.163-.457.237-.727.644-.737 1.152-.018.39.167.805 1.916 4.373 1.06 2.166 1.964 4.083 1.998 4.27.04.179.004.521-.076.75-.093.228-1.109 2.064-2.269 4.088-1.921 3.34-2.11 3.711-2.123 4.107-.008.25.061.557.168.725.328.512.72.644 1.966.676 1.32.029 2.352-.236 3.05-.762.222-.171 1.275-1.313 2.412-2.611 1.918-2.185 2.048-2.32 2.45-2.505.241-.111.601-.232.82-.271.267-.058 2.213.201 5.912.8 3.036.48 5.525.894 5.518.914 0 .026-.121.306-.27.638-.54 1.198-1.515 3.842-3.35 9.021-1.029 2.913-2.107 5.897-2.4 6.62-.703 1.748-.725 1.833-.594 2.286.137.46.45.833.872 1.012.41.177 3.823.24 4.37.085.852-.25 1.44-.688 2.312-1.724 1.166-1.39 3.169-3.948 6.771-8.661 5.8-7.583 6.561-8.49 7.387-8.702.233-.065 2.828-.056 5.784.011 5.827.138 6.64.09 8.62-.5 2.24-.67 4.035-1.65 5.517-3.016 1.136-1.054 1.135-1.014.207-1.962-.357-.38-.767-.777-.902-.893z"
                        fill="#000033"
                      />
                    </svg>
                  )}

                  <div
                    className="mapZone mclarenZone"
                    onClick={() => moveTo(7600)}
                  >
                    <img src="./images/map/1.webp" alt="McLarenPreview" />
                    <p className="text-white text-l absolute uppercase -bottom-8	-left-2.5	">
                      McLaren
                    </p>
                  </div>
                  <div
                    className="mapZone degreeZone"
                    onClick={() => moveTo(15000)}
                  >
                    <img src="./images/map/2.webp" alt="DegreePreview" />
                    <p className="text-white text-l absolute uppercase -bottom-14	-left-3.5	">
                      19 Degree <br /> Aluminum
                    </p>
                  </div>
                  <div
                    className="mapZone alphaZone"
                    onClick={() => moveTo(23000)}
                  >
                    <img src="./images/map/3.webp" alt="AlphaPreview" />
                    <p className="text-white text-l absolute uppercase -bottom-14		">
                      Alpha Bravo
                    </p>
                  </div>
                  <div
                    className="mapZone tegraZone"
                    onClick={() => moveTo(31200)}
                  >
                    <img src="./images/map/4.webp" alt="TegraPreview" />
                    <p className="text-white text-l absolute uppercase text-center -bottom-14">
                      Tegra Lite
                    </p>
                  </div>
                  <div
                    className="mapZone sustainableZone"
                    onClick={() => moveTo(44000)}
                  >
                    <img src="./images/map/5.webp" alt="SustainablePreview" />
                    <p className="text-white text-l absolute  uppercase -bottom-8 	-left-7	">
                      Sustainability
                    </p>
                  </div>
                </>
              )}
              {map && (
                <a
                  className="absolute -right-44 px-20 p-5 text-black bg-white	text-center top-44 transform -translate-y-1/2  z-50"
                  href="https://tumi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discover more on <br />{" "}
                  <span className="font-bold">Tumi.com</span>
                </a>
              )}
            </div>
          </>
        )}
        {isMobile && (
          <div
            className={`mobileMap  flex justify-center overflow-hidden	  ${
              map ? "h-full" : "h-0"
            }`}
          >
            <img
              className="logointroback absolute top-6 left-3.5 h-4 w-auto pointer-events-none	"
              src="./images/intro/tumi-logo_black.png"
              alt="logoblack"
            />
            <div className="listLink w-full h-5/6	m-auto	">
              <div
                className="mapZone mclarenZone"
                style={{ backgroundImage: "url('./images/map/1.webp')" }}
                onClick={() => moveTo(7500)}
              >
                <p className="text-white text-xl	">McLaren</p>
              </div>
              <div
                className="mapZone degreeZone"
                style={{ backgroundImage: "url('./images/map/2.webp')" }}
                onClick={() => moveTo(15500)}
              >
                <p className="text-white text-xl	">19 Degree Aluminum</p>
              </div>
              <div
                className="mapZone alphaZone"
                style={{ backgroundImage: "url('./images/map/3.webp')" }}
                onClick={() => moveTo(23300)}
              >
                <p className="text-white text-xl	">Alpha Bravo</p>
              </div>
              <div
                className="mapZone tegraZone"
                style={{ backgroundImage: "url('./images/map/4.webp')" }}
                onClick={() => moveTo(31200)}
              >
                <p className="text-white text-xl	">Tegra Lite</p>
              </div>
              <div
                className="mapZone sustainableZone"
                style={{ backgroundImage: "url('./images/map/5.webp')" }}
                onClick={() => moveTo(44500)}
              >
                <p className="text-white text-xl	">Sustainability</p>
              </div>
              <a
                className=" text-black w-full text-center  pt-8 inline-block "
                href="https://tumi.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discover more on
                <span className="font-bold"> Tumi.com</span>
              </a>
            </div>
          </div>
        )}

        {map && (
          <button
            className="close absolute top-3.5 right-3.5 z-30 text-4xl	"
            onClick={() => setMap(false)}
          >
            ×
          </button>
        )}
      </div>
    </>
  );
});
