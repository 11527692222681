export default {
  alpha: [
    {
      name: "alpha01",
      image: "./images/screen/alpha-bravo/01.webp",
      position: 1,
    },
    {
      name: "alpha03",
      image: "./images/screen/alpha-bravo/03.webp",
      position: 1,
    },
  ],
  ninteen: [
    {
      name: "19degree01",
      image: "./images/screen/19degree/01.webp",
      position: 3,
    },
    {
      name: "19degree02",
      image: "./images/screen/19degree/02.webp",
      position: 4,
    },
    {
      name: "19degree03",
      image: "./images/screen/19degree/03.webp",
      position: 5,
    },
  ],
  mclaren: [
    {
      name: "mclaren01",
      image: "./images/screen/mclaren/01.webp",
      position: 7,
    },
    {
      name: "mclaren02",
      image: "./images/screen/mclaren/02.webp",
      position: 8,
    },
    {
      name: "mclaren03",
      image: "./images/screen/mclaren/03.webp",
      position: 8,
    },
    {
      name: "mclaren04",
      image: "./images/screen/mclaren/04.webp",
      position: 8,
    },
  ],
  tegra: [
    {
      name: "tegra01",
      image: "./images/screen/tegra/01.webp",
      position: 9,
    },
    {
      name: "tegra02",
      image: "./images/screen/tegra/02.webp",
      position: 10,
    },
    {
      name: "tegra04",
      image: "./images/screen/tegra/04.webp",
      position: 10,
    },
  ],
  voyageur: [
    {
      name: "voyageur01",
      image: "./images/screen/voyageur/01.webp",
      position: 11,
    },
    {
      name: "voyageur02",
      image: "./images/screen/voyageur/02.webp",
      position: 12,
    },
    {
      name: "voyageur03",
      image: "./images/screen/voyageur/03.webp",
      position: 13,
    },
    {
      name: "voyageur04",
      image: "./images/screen/voyageur/04.webp",
      position: 14,
    },
  ],
  rse: [
    {
      name: "rse01",
      image: "./images/screen/rse/01.webp",
      position: 15,
    },
    {
      name: "rse02",
      image: "./images/screen/rse/02.jpg",
      position: 16,
    },
  ],
  videoReplace: [
    {
      name: "rseVideoReplace",
      image: "./images/screen/rse/rse_video.jpg",
    },
    {
      name: "AlphaBravoVideoReplace",
      image: "./images/screen/alpha-bravo/ab_video.jpg",
    },
    {
      name: "19degreeVideoReplace",
      image: "./images/screen/19degree/19degree_video.jpg",
    },
    {
      name: "tegraVideoReplace",
      image: "./images/screen/tegra/tegra_video.jpg",
    },
  ],
};

/*     
    {
        "name": "19degree04",
        "image": "./images/screen/19degree/04.webp",
        "position": 6
    },
    
    {
        "name": "alpha02",
        "image": "./images/screen/alpha-bravo/02.webp",
        "position": 2
    },
*/
