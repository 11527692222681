import { useState, useRef, useEffect } from "react";
import useIntro from "./../Store/Intro";

export function Sound() {
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(0);
  const [enterExperience, introDisplay] = useIntro((state) => [
    state.enterExperience,
    state.introDisplay,
  ]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      if (isPlaying) {
        // Start the audio and fade in the volume
        audio.play();
        const fadeInterval = setInterval(() => {
          setVolume((prevVolume) => {
            if (prevVolume < 0.92) {
              audioRef.current.volume = prevVolume;
              return prevVolume + 0.05;
            } else {
              clearInterval(fadeInterval);
              return prevVolume;
            }
          });
        }, 50);
      } else {
        // Fade out the volume and pause the audio
        const fadeInterval = setInterval(() => {
          setVolume((prevVolume) => {
            if (prevVolume > 0.06) {
              audioRef.current.volume = prevVolume;
              return prevVolume - 0.05;
            } else {
              clearInterval(fadeInterval);
              audio.pause();
              return prevVolume;
            }
          });
        }, 50);
      }
    }
  }, [isPlaying]);
  return (
    <>
      {(enterExperience || !introDisplay) && (
        <>
          <audio autoPlay ref={audioRef} loop src="./sound/music_1.mp3" />
          <button
            className="fixed   left-3.5    bottom-2 	 sm:bottom-11"
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <div className={`icon ${!isPlaying ? "pause" : ""}`}>
              <span />
              <span />
              <span />
            </div>
          </button>
        </>
      )}
    </>
  );
}
