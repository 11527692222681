import React, { useState, cloneElement } from "react";
import { useTranslation } from "react-i18next";

export function Dropdown({ trigger, menu, languaget }) {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const language = i18n.translator.language;

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown z-[46] absolute drop-shadow-2xl bottom-1 left-10	 sm:bottom-10 sm:left-10">
      {cloneElement(trigger, {
        onMouseEnter: handleOpen,
      })}
      <ul
        className={` dropdown_menu dropdown_menu--animated`}
        onMouseLeave={() => setOpen(false)}
      >
        {menu.map((menuItem, index) => (
          <li key={index} className={`menu-item dropdown_item-${index}`}>
            {cloneElement(menuItem, {
              onClick: () => {
                menuItem.props.onClick();
                setOpen(false);
              },
            })}
          </li>
        ))}
      </ul>
    </div>
  );
}
