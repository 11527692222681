/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { isMobile } from "react-device-detect";

export function Train(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/whitetrain.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions?.bulletTrain.play();
    actions["bulletTrainWagon_01"].play();
    actions["bulletTrainWagon_02"].play();
    actions["bulletTrainWagon_03"].play();
    actions["bulletTrain_04"].play();
  }, [actions]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="bulletTrain"
          position={[-37.82, 0.83, 55.04]}
          rotation={[3.14, -1.15, 3.14]}
          scale={0.7}
        >
          <mesh
            name="Object_0003"
            geometry={nodes.Object_0003.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0003_1"
            geometry={nodes.Object_0003_1.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0003_2"
            geometry={nodes.Object_0003_2.geometry}
            material={materials.Vitres}
          />
          <mesh
            name="Object_0003_3"
            geometry={nodes.Object_0003_3.geometry}
            material={materials.Silver}
          />
        </group>
        <group
          name="bulletTrain_04"
          position={[-48.92, 0.81, 38.07]}
          rotation={[0, 0.44, 0]}
          scale={0.7}
        >
          <mesh
            name="Object_0006"
            geometry={nodes.Object_0006.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0006_1"
            geometry={nodes.Object_0006_1.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0006_2"
            geometry={nodes.Object_0006_2.geometry}
            material={materials.Vitres}
          />
          <mesh
            name="Object_0006_3"
            geometry={nodes.Object_0006_3.geometry}
            material={materials.Silver}
          />
        </group>
        <group
          name="bulletTrainWagon_01"
          position={[-41.15, 0.89, 50.8]}
          rotation={[3.14, -0.72, 3.14]}
          scale={0.7}
        >
          <mesh
            name="Object_0"
            geometry={nodes.Object_0.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0_1"
            geometry={nodes.Object_0_1.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0_2"
            geometry={nodes.Object_0_2.geometry}
            material={materials.Vitres}
          />
        </group>
        <group
          name="bulletTrainWagon_02"
          position={[-43.89, 0.89, 46.87]}
          rotation={[3.14, -0.58, 3.14]}
          scale={0.7}
        >
          <mesh
            name="Object_0004"
            geometry={nodes.Object_0004.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0004_1"
            geometry={nodes.Object_0004_1.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0004_2"
            geometry={nodes.Object_0004_2.geometry}
            material={materials.Vitres}
          />
        </group>
        <group
          name="bulletTrainWagon_03"
          position={[-46.43, 0.88, 42.8]}
          rotation={[3.14, -0.52, 3.14]}
          scale={0.7}
        >
          <mesh
            name="Object_0005"
            geometry={nodes.Object_0005.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0005_1"
            geometry={nodes.Object_0005_1.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0005_2"
            geometry={nodes.Object_0005_2.geometry}
            material={materials.Material}
          />
          <mesh
            name="Object_0005_3"
            geometry={nodes.Object_0005_3.geometry}
            material={materials.Vitres}
          />
          <mesh
            name="Object_0005_4"
            geometry={nodes.Object_0005_4.geometry}
            material={materials.Vitres}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/whitetrain.glb");
