import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function PaperBoat(props) {
  const group = useRef();
  const { scene, animations } = useGLTF("./models/whiteboat.glb");
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["boat"].play();
  }, [actions]);
  return (
    <group ref={group} {...props} position={[0, 0.77, 0]} dispose={null}>
      <group name="Scene">
        <primitive object={scene} />
      </group>
    </group>
  );
}

useGLTF.preload("./models/objects/boat.glb");
