import {
  Text3D,
  useCursor,
  useTexture,
  MeshTransmissionMaterial,
  Plane,
  Image,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useMemo, useRef, useState, useContext, useEffect } from "react";
// import PopupContext from "../../Context/PopupContext";
import * as THREE from "three";
import usePopupContent from "../../Store/Popup";
import { isMobile } from "react-device-detect";

export function Text(props) {
  const text = useRef();
  const [near, setNear] = useState(0);
  const [hovered, setHovered] = useState(false);

  //  const { display, setDisplay } = useContext(PopupContext);
  const [popupContent, setPopupContent] = usePopupContent((state) => [
    state.popupContent,
    state.setPopupContent,
  ]);

  const position = new THREE.Vector3(
    props.text.position[0],
    props.text.position[1],
    props.text.position[2]
  );
  const config = useMemo(
    () => ({
      size: props.text.size,
      height: 0.1,
      curveSegments: 5,
      position: props.text.position,
      rotation: props.text.rotation,
      metalness: 0.15,
      roughness: 0.1,
    }),
    []
  );
  useCursor(props.text.displayLink ? hovered : false);
  var showPopup = function (element) {
    if (element.distance <= 500) {
      setPopupContent(props.text.reference);
      //    setDisplay(props.text.reference);
      props.detectClick();
    }
  };

  useFrame((state) => {
    if (
      state.camera.position.distanceTo(text.current.position) <
      props.text.showDistance
    ) {
      const positionShow = new THREE.Vector3(
        props.text.positionShow[0],
        props.text.positionShow[1],
        props.text.positionShow[2]
      );
      // We enter the zone
      setNear(true);

      text.current.position.lerp(positionShow, 0.01);
    } else {
      setNear(false);
      text.current.position.lerp(position, 0.01);
    }
  });

  return (
    <>
      {props.text.displayLink && (
        <>
          <Plane
            args={[5, 2]}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
            onPointerOut={(e) => setHovered(false)}
            onPointerUp={showPopup}
            position={[
              props.text.position[0] + 0.1,
              props.text.position[1] + 0.1,
              props.text.position[2] + 0.1,
            ]}
            rotation={props.text.rotation}
          >
            <meshBasicMaterial transparent opacity={0} />
          </Plane>
          <Image
            url={
              hovered
                ? "./images/icons/hotspot_on_gris.png"
                : "./images/icons/hotspot_off_gris.png"
            }
            position={props.text.position}
            rotation={props.text.rotation}
            transparent
            onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
            onPointerOut={(e) => setHovered(false)}
            onPointerUp={showPopup}
          />
        </>
      )}
      {/* <Html position={props.text.position} occlude>
        <img src="./images/icons/hotspot_on.png" />
        AAA
          color={hovered ? "#666" : "#555"}
      </Html>*/}
      <Text3D
        // castShadow
        font={"./font/pn.json"}
        {...config}
        ref={text}
        onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
        onPointerOut={(e) => setHovered(false)}
        onPointerUp={showPopup}
      >
        {props.text.text}
        <meshBasicMaterial color={"#454545"} />
        {/* <MeshTransmissionMaterial
            anisotropy={0.5}
            chromaticAberration={2}
            transmission={0.7}
            distortion={4}
            distortionScale={0.7}
            ior={0.8}
            roughness={0.2}
            color={"#555"}
          /> */}
      </Text3D>
    </>
  );
}

/*
    <PivotControls rotation={[0, -Math.PI / 2, 0]} anchor={[1, -1, -1]} scale={75} depthTest={false} fixed lineWidth={2} >
  </PivotControls>
 */
