import { Water } from "three-stdlib";
import { useRef, useMemo } from "react";
import { useThree, useLoader, useFrame, extend } from "@react-three/fiber";
import * as THREE from "three";

extend({ Water });

export function WaterShader() {
  const ref = useRef();
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, "./water.jpeg");
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(1000, 1000), []);
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      waterColor: 0x80a4fc,
      distortionScale: 3.7,
      fog: false,
      format: gl.encoding,
    }),
    [waterNormals]
  );
  useFrame(
    (state, delta) => (ref.current.material.uniforms.time.value += delta / 25)
  );
  return (
    <water
      ref={ref}
      position={[0, -0.5, 0]}
      args={[geom, config]}
      rotation-x={-Math.PI / 2}
    />
  );
}
