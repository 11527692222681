import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { isMobile } from "react-device-detect";

export function FullEnv(props) {
  const group = useRef();
  const { scene, nodes, materials, animations } = useGLTF(
    "./models/tumiv14.glb"
  );
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    if (!isMobile) {
      actions["bulletTrain"].play();
      actions["bulletTrainWagon_01"].play();
      actions["bulletTrainWagon_02"].play();
      actions["bulletTrainWagon_03"].play();
      actions["bulletTrain_04"].play();
    }
  }, [actions]);

  return <primitive frustumCulled={true} object={scene} />;
}

useGLTF.preload("./models/tumiv14.glb");
