import { useRef } from "react";
import {
  EffectComposer,
  Selection,
  Outline,
} from "@react-three/postprocessing";
import { isMobile } from "react-device-detect";

import { BagGeneric } from "./BagGeneric";

export const Bags = ({ props }) => {
  const pointer = useRef();

  const detectClick = () => {
    if (pointer.current) {
      setTimeout(() => {
        pointer.current.unlock();
      }, 200);
    }
  };

  return isMobile ? (
    <>
      {props?.data?.length &&
        props.data.map((bag) => (
          <BagGeneric
            file={`./models/bags/${bag.item}.glb`}
            key={bag.item}
            detectClick={detectClick}
            name={bag.item}
          />
        ))}
    </>
  ) : (
    <EffectComposer autoClear={false} disableNormalPass>
      <Selection>
        <Outline hiddenEdgeColor="#99c4ac" edgeStrength={2} blur xray={false} />
        {props?.data?.length &&
          props.data.map((bag) => (
            <BagGeneric
              file={`./models/bags/${bag.item}.glb`}
              key={bag.item}
              detectClick={detectClick}
              name={bag.item}
            />
          ))}
      </Selection>
    </EffectComposer>
  );
};
