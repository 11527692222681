import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languageDetector = new LanguageDetector();
//console.log(languageDetector);
i18n
  .use(Backend)
  .use(LanguageDetector) // If we want to detect navigator language
  .use(initReactI18next)
  .init({
    fallbackLng: "fr-FR",
    debug: true,
    whitelist: ["en-GB", "fr-FR", "en-US", "en"],
    supportedLngs: ["en-GB", "fr-FR", "en-US", "en"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

console.log(i18n);
export default i18n;
