import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Preload, useGLTF } from "@react-three/drei";
import { isMobile } from "react-device-detect";

import { Intro } from "./Ui";
import { MainScene } from "./Scene/Environment/MainScene";
import { Train } from "./Scene/Objects/Train";

//Added by Aaron
import { WaterShader } from "./Scene/Environment/WaterShader";
import { useHover } from "./Store/Hover";

import { Light } from "./Scene/Environment";

import { Screens } from "./Scene/Screens/Screens";
import { ScreensMobile } from "./Scene/Screens/ScreensMobile";

import texts from "./Scene/Texts/ListText";
import { Text } from "./Scene/Texts/Text";

import { NewCamera } from "./Scene/Camera/NewCamera";
import { Bags } from "./Scene/Bags/Bags";
import { Montgolfiere } from "./Scene/Objects/Montgolfiere";
import { Montgolfiere02 } from "./Scene/Objects/Montgolfiere02";
import { LogoTumi } from "./Scene/Objects/LogoTumi";
import { Avions } from "./Scene/Objects/Avions";
import { PaperBoat } from "./Scene/Objects/PaperBoat";
import { PodiumRSE3 } from "./Scene/Environment/Podium_RSE3";
import { PodiumT } from "./Scene/Assets/PodiumT";
import { AvionPapier } from "./Scene/Objects/AvionPapier";

function Ready({ setReady }) {
  useEffect(() => {
    setReady(true);
  }, []);

  return null;
}

export default function App(props) {
  const [hover, setHover] = useHover((state) => [state.hover, state.setHover]);

  const listNuagePosition = [
    [150, 100, 80],
    [27, 96, -77],
    [-290, 55, -295],
    [29, 34, 62],
    [134, 73, -107],
    [300, 64, 17],
    [-228, 95, 173],
    [-350, 112, 402],
    [170, 62, 302],
    [-110, 42, -25],
    [130, 62, -302],
    [-150, 112, -142],
  ];

  const listNuageScale = [1, 1, 1.2, 0.4, 0.8, 0.7, 1, 1, 1, 0.8, 0.6, 0.8];

  const [ready, setReady] = useState(false);

  const pointer = useRef();

  const detectClick = () => {
    if (pointer.current) {
      setTimeout(() => {
        pointer.current.unlock();
      }, 200);
    }
  };
  /*
  useGLTF("./models/tumiv16.glb");
  useGLTF("./models/bags/19_Degree_Alu.glb");
  useGLTF("./models/bags/19_Degree_Alu_Open.glb");
  useGLTF("./models/bags/19degreePC.glb");
  useGLTF("./models/bags/AB_Mason.glb");
  useGLTF("./models/bags/AB_Navigation.glb");
  useGLTF("./models/bags/AB_Sac-ceinture.glb");
  useGLTF("./models/bags/ML_Aero.glb");
  useGLTF("./models/bags/ML_Duffel.glb");
  useGLTF("./models/bags/ML_Velocity.glb");
  useGLTF("./models/bags/Tegra_nopocket.glb");
  useGLTF("./models/bags/Tegra_w_pocket.glb");
*/
  return (
    <Intro ready={ready}>
      <Canvas
        className="canvas"
        id="capturePointer"
        position={[5, 5, 5]}
        style={{ cursor: hover ? "pointer" : null }}
      >
        {/* <Perf position="top-left" /> */}
        <Light />
        <Suspense fallback={<Ready setReady={setReady} />}>
          <Preload all />
          <NewCamera />

          <MainScene />
          <Train />
          {/* <Podium /> */}
          <PodiumRSE3 />
          <PodiumT />
          <AvionPapier />
          <Montgolfiere />
          <Montgolfiere02 />
          <PaperBoat />
          <LogoTumi />
          <Screens />
          <Avions />
          {Array.from({ length: texts.length }, (_, i) => (
            <Text text={texts[i]} key={i} detectClick={detectClick} />
          ))}
          <Bags props={props} />
          <WaterShader />
        </Suspense>
      </Canvas>
    </Intro>
  );
}
