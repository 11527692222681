export default [
  {
    text: "TUMI MCLAREN",
    reference: "mclarenstory",
    position: [29.5, 3.5, -1.28],
    rotation: [0, -Math.PI / 2, 0],
    size: 1,
    displayLink: true,
  },
  /* {
    text: "Story", // MCLAREN
    position: [37.95, 1.61, -19.05],
    positionShow: [37.95, 1.61, -19.25],
    showDistance: 20,
    rotation: [0, 0, 0],
    size: 0.8,
  },*/
  {
    text: "Features", // MCLAREN
    reference: "tumimclarenfeature",
    position: [35.81, 4.8, -2.0],
    positionShow: [36.81, 3.5, -2.0],
    showDistance: 20,
    rotation: [0, Math.PI, 0],
    size: 1,
    displayLink: true,
  },
  /*  {<canvas style="display: block; width: 1192px; height: 937px;" data-engine="three.js r147" width="1192" height="937">
    text: "in class", // MCLAREN
    reference: "bestofmclaren",
    position: [36.81, 2.1, -1.9],
    positionShow: [36.81, 2.1, -2.0],
    showDistance: 20,
    rotation: [0, Math.PI, 0],
    size: 1,
  },*/
  /*  {
        "text": "VOYAGEUR",
        "position": [28.80, 3.5, 1.8],
        "rotation": [0, -Math.PI /2 , 0],
        size: 1
    }, */
  {
    text: "ALPHA BRAVO",
    reference: "abstory",
    position: [-42, 13.62, 1.2],
    rotation: [0, Math.PI / 2, 0],
    size: 0.8,
    displayLink: true,
  },
  /* {
    text: "Story", // ALPHABRAVO
    position: [-42.8, 4.5, -19],
    positionShow: [-42.8, 4.5, -18.85],
    showDistance: 25,
    rotation: [0, 0, 0],
    size: 0.8,
  },*/
  {
    text: "Features", // ALPHABRAVO
    reference: "alphabravofeature",
    position: [-29.9, 1.3, 4.2],
    positionShow: [-33.3, 7.9, -19.7],
    showDistance: 20,
    rotation: [0, Math.PI, 0],
    size: 0.7,
    displayLink: true,
  },
  /* {
    text: "in class", // ALPHABRAVO
    position: [-33.3, 6.9, -19.85],
    positionShow: [-33.3, 6.9, -19.7],
    showDistance: 20,
    rotation: [0, 0, 0],
    size: 0.8,
  },*/
  {
    text: "TEGRA",
    reference: "tegrastory",
    position: [-38.27, 2.56, 22.38],
    rotation: [0, Math.PI / 2, 0],
    size: 0.8,
    displayLink: true,
  },
  {
    text: "LITE",
    reference: "tegrastory",
    position: [-38.27, 1.26, 21.88],
    rotation: [0, Math.PI / 2, 0],
    size: 0.8,
    displayLink: false,
  },
  {
    text: "®",
    reference: "tegrastory",
    position: [-38.27, 1.86, 19.52],
    rotation: [0, Math.PI / 2, 0],
    size: 0.5,
    displayLink: false,
  },
  {
    text: "Features", // TEGRA
    reference: "tegrafeature",
    position: [-29.5, 2.86, 17.2],
    positionShow: [-37.97, 2.26, 35.19],
    showDistance: 25,
    rotation: [0, 0, 0],
    size: 0.6,
    displayLink: true,
  },
  /* {
    text: "in class", // TEGRA
    position: [-38.07, 1.26, 35.19],
    positionShow: [-37.97, 1.26, 35.19],
    showDistance: 25,
    rotation: [0, Math.PI / 2, 0],
    size: 0.8,
  },*/
  /* {
    text: "Story", // TEGRA
    reference: "tegrastory",
    position: [-29.5, 3.4, 17],
    positionShow: [-29.5, 3.4, 17.2],
    showDistance: 15,
    rotation: [0, 0, 0],
    size: 0.9,
  },*/
  {
    text: "SUSTAINABILITY", // Haut
    position: [-29, 17.13, 45.8],
    rotation: [0, Math.PI, 0],
    size: 0.5,
  },
  {
    text: "SUSTAINABILITY", // Haut
    position: [-13, 4.13, 43.5],
    rotation: [0, Math.PI, 0],
    size: 0.4,
  },
  /*  {
        "text": "SUSTAINABLE", // Bas
        "position": [-9.75, 5.82, 43.88],
        "rotation": [0, Math.PI, 0],
        size: 0.7
    },*/
  {
    text: "19 DEGREE",
    reference: "19degreestory",
    position: [-3.3, 10, -38.5],
    rotation: [0, 0, 0],
    size: 1,
    displayLink: true,
  },
  {
    text: "ALUMINUM",
    reference: "19degreestory",
    position: [-3.3, 8, -38.5],
    rotation: [0, 0, 0],
    size: 1,
    displayLink: false,
  },
  /* {
    text: "Story", // 19DEGREE
    positionShow: [-12.5, 4.44, -34.9],
    position: [-12.5, 4.44, -35.05],
    showDistance: 20,
    rotation: [0, 0, 0],
    size: 0.5,
  },*/
  {
    text: "Features", // 19DEGREE
    reference: "19degreefeature",
    positionShow: [10.4, 4.7, -22.7],
    position: [10.4, 4.7, -22.7],
    showDistance: 20,
    rotation: [0, -Math.PI / 2, 0],
    size: 1,
    displayLink: true,
  },
  /* {
    text: "in class", // 19DEGREE
    positionShow: [10.4, 3.5, -22.7],
    position: [10.8, 3.5, -22.7],
    showDistance: 20,
    rotation: [0, -Math.PI / 2, 0],
    size: 1,
  },*/
];
