import { useTranslation } from "react-i18next";
import useIntro from "./../Store/Intro";

export function Tuto() {
  const { t } = useTranslation();

  const [lastclicked, setLastClicked] = useIntro((state) => [
    state.lastclicked,
    state.setLastClicked,
  ]);

  const [setIntroDisplay] = useIntro((state) => [state.setIntroDisplay]);

  const [setTutoDisplay] = useIntro((state) => [state.setTutoDisplay]);

  const tutorialClick = () => {
    setLastClicked(true);
    setTimeout(() => {
      setTutoDisplay(false);
      setIntroDisplay(false);
    }, 500);
  };
  return (
    <>
      <div
        className={`fullscreen prescreen z-10 ${lastclicked && "hide"}`}
        onClick={tutorialClick}
      >
        <div className="flex flex-col desktop bg-white items-center mt-28 p-10 text-black ">
          <h3 className="w-full text-black text-center pb-16 text-4xl">
            Tutorial
          </h3>
          <div className="flex">
            <div className="w-72  flex items-center flex-wrap flex-col justify-items-center	">
              <div className="blockImage w-60">
                <video muted loop playsInline autoPlay>
                  <source src="./videos/tuto/1_eplore.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="pt-5 text-center grow ">
                <p>{t("clickDrag")}</p>
              </div>
            </div>
            <div className="w-72 h-52 flex items-center flex-wrap flex-col justify-items-center">
              <div className="blockImage w-60">
                <video muted loop playsInline autoPlay>
                  <source src="./videos/tuto/2_scroll.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="pt-5 text-center grow">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("scrollToExplore"),
                  }}
                ></p>
              </div>
            </div>
            <div className="w-72 h-52 flex items-center flex-wrap flex-col justify-items-center">
              <div className="blockImage w-60">
                <video muted loop playsInline autoPlay>
                  <source src="./videos/tuto/3_click.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="pt-5 text-center grow ">
                <p>{t("clickHotstop")}</p>
              </div>
            </div>
          </div>

          <button className="  uppercase cursor-pointer	w-52 h-14	 transition-all	 border-2	border-black p-3 text-black transition-colors  hover:border-red		 hover:text-red	mt-10  hover:bg-white">
            Click to continue
          </button>
        </div>
        <div
          className=" flex flex-col flex-wrap	w-full items-center	h-5/6 mobile-only mobile"
          style={{ flexFlow: "wrap" }}
        >
          <div className="item w-full">
            <div className="blockImage w-4/5">
              <video muted loop playsInline autoPlay>
                <source src="./videos/tuto/1_eplore.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div>
            <p className="text-sm py-3 w-screen	text-center">
              Swipe up or down to explore
            </p>
          </div>
          <div className="item w-full">
            <div className="blockImage w-4/5">
              <video muted loop playsInline autoPlay>
                <source src="./videos/tuto/2_scroll.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div>
            <p className="text-sm py-3 w-screen	text-center">{t("swipeLeft")}</p>
          </div>
          <div className="item w-full">
            <div className="blockImage w-4/5">
              <video muted loop playsInline autoPlay>
                <source src="./videos/tuto/3_click.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div>
            <p className="text-sm py-3 w-screen	text-center">
              {t("clickHotstop")}
            </p>
          </div>
          <button className="  uppercase cursor-pointer	w-52 h-14	mx-auto transition-all	 border-2	border-white p-3 text-white transition-colors  hover:bg-white">
            Click to continue
          </button>
        </div>
      </div>
    </>
  );
}
