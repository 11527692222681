import { create } from "zustand";

const useCameraStore = create((set) => ({
  popupOpen: false, // To disable scroll when popup/wishlist/map is open
  setPopupOpen: (popupState) =>
    set((state) => {
      return {
        ...state,
        popupOpen: popupState,
      };
    }),
  time: 0,
  updateTime: (newTime) =>
    set((state) => {
      return {
        ...state,
        time: newTime,
      };
    }),
}));

export default useCameraStore;

export const useCameraPosition = create((set) => ({
  cameraPosition: 0,
  setCameraPosition: (cameraPosition) => set({ cameraPosition }),
}));

export const useMap = create((set) => ({
  map: false,
  setMap: (map) => set({ map }),
}));

export const useGlobalPos = create((set) => ({
  globalPos: 0,
  setGlobalPos: (globalPos) => set({ globalPos }),
}));

export const useCameraLerpInterpolation = create((set) => ({
  lerpInterpolation: 0.03,
  setLerpInterpolation: (lerpInterpolation) => set({ lerpInterpolation }),
}));
