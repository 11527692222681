import React, { useRef, useContext, useEffect, useState } from "react";
import { Select } from "@react-three/postprocessing";
import { useGLTF, useEnvironment } from "@react-three/drei";
// import PopupContext from "../../Context/PopupContext";
import { applyProps, useLoader, useThree, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import usePopupContent from "../../Store/Popup";
import { isMobile } from "react-device-detect";
import { useHover } from "../../Store/Hover";

export function BagGeneric(props) {
  const { scene, materials } = useGLTF(props.file);
  const ref = useRef();
  const dist = 8.5;
  const [enabled, setEnabled] = useState(false);

  const [popupContent, setPopupContent] = usePopupContent((state) => [
    state.popupContent,
    state.setPopupContent,
  ]);

  const [hover, setHover] = useHover((state) => [state.hover, state.setHover]);

  const { camera } = useThree();

  const handleMouseEnter = (element) => {
    if (element.distance <= dist) {
      setHover(true);
      setEnabled(true);
    }
  };

  const handleMouseLeave = () => {
    setHover(false);
    setEnabled(false);
  };

  var showPopup = function (element) {
    if (element.distance <= dist) {
      setPopupContent(props.name);
      props.detectClick();
    }
  };

  if (materials.M_Luggage_Tag_19Degree) {
    //M_Luggage_Tag_19Degree
    //Valise.003
    //valise_Close.002
    /* applyProps(materials.M_Luggage_Tag_19Degree, {
      metalness: 0.05,
      roughness: 0.1,
      toneMapped: false,
      envMapIntensity: 0.8,
    });*/
    applyProps(materials["valise_Close.002"], {
      // metalness: 1,
      //  reflectivity: 0,
      //   transmission: 0,
      //   ior: 1,
      sheenRoughness: 0,
      roughness: 0.5,
      //   toneMapped: false,
      // envMapIntensity: 0.5,
    });
    /* applyProps(materials["Valise.003"], {
      metalness: 0.05,
      roughness: 0.1,
      toneMapped: false,
      envMapIntensity: 0.8,
    });*/
  }

  useEffect(() => {
    if (enabled) {
      ref.current.traverse((object) => {
        if (object.isMesh) {
          materials[object.material.name].transparent = true;
          materials[object.material.name].opacity = 1;
          materials[object.material.name].emissive.set(0xffffff);
          materials[object.material.name].emissiveIntensity = 0.05;
        }
      });
    } else {
      ref.current.traverse((object) => {
        if (object.isMesh) {
          materials[object.material.name].transparent = true;
          materials[object.material.name].opacity = 1;
          materials[object.material.name].envMapIntensity = 1;
          materials[object.material.name].emissive.set(0x000);
          materials[object.material.name].emissiveIntensity = 0;
        }
      });
    }
  }, [enabled]);

  return isMobile ? (
    <primitive
      frustumCulled={true}
      ref={ref}
      object={scene}
      onPointerUp={showPopup}
      onPointerEnter={handleMouseEnter}
      onPointerLeave={handleMouseLeave}
    />
  ) : (
    <Select enabled={enabled}>
      <primitive
        background={false}
        frustumCulled={true}
        ref={ref}
        object={scene}
        onPointerUp={showPopup}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
      />
    </Select>
  );
}
/*position={props.position} scale={props.scale}*/
//<PivotControls ref={ref} rotation={[0, -Math.PI / 2, 0]} anchor={[1, -1, -1]} scale={75} depthTest={false} fixed lineWidth={2} >
//</PivotControls>
