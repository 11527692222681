import React, { useRef, useState, useEffect } from "react";
import PopupContext from "./Context/PopupContext";
import { useTranslation } from "react-i18next";
import useCartStore from "./Store/Cart";
import usePopupContent from "./Store/Popup";
import { useCameraPosition } from "./Store/Camera";
import { isMobile } from "react-device-detect";

import { Carousel } from "react-carousel-minimal";
import useCurrencySelected from "./Store/Currency";
import { Language } from "./Ui/Components/Language";

export default function Popup(props) {
  const addToCart = useCartStore((state) => state.addToCart);
  const [currencySelected, setCurrencySelected] = useCurrencySelected(
    (state) => [state.currencySelected, state.setCurrencySelected]
  );
  const [popupContent, setPopupContent] = usePopupContent((state) => [
    state.popupContent,
    state.setPopupContent,
  ]);
  const [cameraPosition, setCameraPosition] = useCameraPosition((state) => [
    state.cameraPosition,
    state.setCameraPosition,
  ]);

  const [display, setDisplay] = useState(false);
  const [selectedTextData, setSelectedTextData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [activeTab, setActiveTab] = useState("features");
  const { t, i18n } = useTranslation();
  let carousel = useRef(null);

  const language = i18n.translator.language;
  const fallbackLanguage = i18n.options.fallbackLng[0];

  const [ArrayImages, setArrayImages] = useState();

  var showTab = function (element) {
    setActiveTab(element.target.title);
  };

  useEffect(() => {
    if (popupContent) {
      let daaaata = props.data.find((obj) => {
        return obj.item === popupContent;
      });
      let dataText = props.dataText.find((obj) => {
        return obj.reference === popupContent;
      });

      if (daaaata) {
        setSelectedData(daaaata);
        setSelectedTextData(null);
        setDisplay(true);
        if (daaaata.images.length > 1) {
          let test = daaaata.images.map((imge, index) => {
            return { image: imge, caption: "" };
          });
          setArrayImages(test);
        }
      } else if (dataText) {
        setSelectedTextData(dataText);
        setSelectedData(null);
        setDisplay(true);
      }
    }
  }, [popupContent]);

  /* if (display) {
    selectedData = props.data.find((obj) => {
      return obj.item === display;
    });
    selectedTextData = props.dataText.find((obj) => {
      return obj.reference === display;
    });
  }
*/
  var stopProp = function (element) {
    if (display) {
      element.stopPropagation();
    }
  };

  var handleCartProducts = function () {
    addToCart(selectedData);
    // setDisplay(null);
    setDisplay(false);
    setTimeout(() => {
      setPopupContent(null);
      setSelectedData(null);
      setSelectedTextData(null);
    }, 1000);
  };

  /*  <Swiper
                            style={{
                              "--swiper-navigation-color": "#fff",
                              "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Thumbs]}
                            className="mySwiper2"
                          >
                            {selectedData.images.map((image, index) => (
                              <SwiperSlide key={image}>
                                <img
                                  src={image}
                                  alt={
                                    selectedData.title[language] ||
                                    selectedData.title[fallbackLanguage]
                                  }
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                          >
                            {selectedData.images.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={image}
                                  alt={
                                    selectedData.title[language] ||
                                    selectedData.title[fallbackLanguage]
                                  }
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>*/

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleMenuOne = () => {
    setCurrencySelected("€");
    changeLanguage("en-FR");
  };

  const handleMenuTwo = () => {
    setCurrencySelected("£");
    changeLanguage("en-GB");
  };

  return (
    <>
      <div
        className={`smooth-show fixed top-0 left-0 z-[45]  bg-grey	 bg-opacity-50  w-full overflow-y-auto overflow-x-hidden outline-none  ${
          display ? "opacity-100 h-full showing" : "opacity-0	 h-0 "
        }`}
      >
        <div
          className={`popup overflow-scroll sm:overflow-auto py-5 px-2 h-full w-full sm:p-10 ${
            selectedTextData && selectedTextData?.small && "small"
          }`}
          onClick={stopProp}
        >
          <button
            onClick={() => {
              setDisplay(false);
              setTimeout(() => {
                setPopupContent(null);
                setSelectedData(null);
                setSelectedTextData(null);
              }, 1000);
            }}
            id="capturePointer"
            className="closeButton desktop absolute right-5 top-4 text-4xl"
          >
            ×
          </button>
          {selectedTextData && (
            <>
              <div className="storypopup flex flex-col 	 h-full overflow-x-hidden">
                <h2 className="redTitle text-2xl font-semibold py-5 grow-0 w-full sm:text-2xl">
                  {selectedTextData.title}
                </h2>
                <div
                  className="px-10 grow flex flex-col justify-center "
                  dangerouslySetInnerHTML={{ __html: selectedTextData.content }}
                />
              </div>
            </>
          )}
          {selectedData && (
            <>
              <div className="inner-wrapper">
                <div className="product-col-2 product-info_top">
                  <h1>
                    {selectedData.title[language] ||
                      selectedData.title[fallbackLanguage]}
                  </h1>
                  <span className="collection">{selectedData.collection}</span>
                  {!isMobile && (
                    <p className="price">
                      {language === "en-GB" && <span>£</span>}
                      {selectedData.price[language] ||
                        selectedData.price[fallbackLanguage]}
                      {language === "fr-FR" && <span>€</span>}
                      {language === "en-FR" && <span>€</span>}
                      {language === "en" && <span>€</span>}
                      {language !== "fr-FR" &&
                        language !== "en" &&
                        language !== "en-GB" && <span>€</span>}
                    </p>
                  )}
                  <div className="color">
                    <span>{t("Color")} :</span>{" "}
                    {selectedData.color[language] ||
                      selectedData.color[fallbackLanguage]}
                  </div>
                </div>
                <div className="product-col-1 product-image-container sm:my-3.5">
                  <div className="product-main-image-container">
                    <div className="product-main-image">
                      {!selectedData.images && (
                        <img
                          src={selectedData.image}
                          alt={
                            selectedData.title[language] ||
                            selectedData.title[fallbackLanguage]
                          }
                        />
                      )}
                      {selectedData.images && (
                        /*       <Carousel
                          ref={(el) => (carousel = el)}
                          animationHandler="fade"
                          showIndicators={false}
                          showStatus={false}
                          showArrows={false}
                          verticalSwipe={"natural"}
                          preventMovementUntilSwipeScrollTolerance={true}
                          swipeScrollTolerance={100}
                          width="80%"
                        >
                          {selectedData.images.map((image, index) => (
                            <div key={index}>
                              <img
                                src={image}
                                alt={
                                  selectedData.title[language] ||
                                  selectedData.title[fallbackLanguage]
                                }
                              />
                            </div>
                          ))}
                        </Carousel>  */

                        <Carousel
                          data={ArrayImages}
                          time={2000}
                          width="850px"
                          height="500px"
                          captionStyle={captionStyle}
                          radius="10px"
                          slideNumber={false}
                          slideNumberStyle={slideNumberStyle}
                          captionPosition="bottom"
                          automatic={false}
                          dots={false}
                          pauseIconColor="white"
                          pauseIconSize="40px"
                          slideBackgroundColor="darkgrey"
                          slideImageFit="cover"
                          thumbnails={true}
                          thumbnailWidth="70px"
                          style={{
                            textAlign: "center",
                            maxWidth: "850px",
                            maxHeight: "500px",
                            margin: "40px auto",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="product-col-2 product-info_product-details">
                  <ul className="tab-list">
                    <li
                      title="features"
                      onClick={showTab}
                      className={`underline text-lightgrey hover:text-red ${
                        activeTab === "features" ? "active" : null
                      }`}
                    >
                      {t("Features")}
                    </li>
                    <li>|</li>
                    <li
                      title="about"
                      onClick={showTab}
                      className={`underline text-lightgrey hover:text-red ${
                        activeTab === "about" ? "active" : null
                      }`}
                    >
                      {t("About")}
                    </li>
                  </ul>

                  {activeTab === "features" && (
                    <div className="product-specifications">
                      <p className="sku-label text-darkgrey">
                        <strong className="text-grey">{t("Style")} :</strong>{" "}
                        {selectedData.reference}
                      </p>
                      <ul className="product-specifications__list">
                        <li className="customDimension-Outside">
                          <p className="title">{t("Dimensions")} </p>
                          <p className="value">
                            {selectedData.dimension[language] ||
                              selectedData.dimension[fallbackLanguage]}
                          </p>
                        </li>
                        <li className="customProductWeight">
                          <p className="title">{t("Weight")} </p>
                          <p className="value">
                            {selectedData.weight[language] ||
                              selectedData.weight[fallbackLanguage]}
                          </p>
                        </li>
                        <li className="customMaterial">
                          <p className="title">{t("Material")} </p>
                          <p className="value">
                            {selectedData.material[language] ||
                              selectedData.material[fallbackLanguage]}
                          </p>
                        </li>
                      </ul>
                    </div>
                  )}
                  {activeTab === "about" && (
                    <div
                      className="product-specifications text-sm	"
                      dangerouslySetInnerHTML={{
                        __html:
                          selectedData.description[language] ||
                          selectedData.description[fallbackLanguage],
                      }}
                    ></div>
                  )}
                  {!isMobile && (
                    <button
                      onClick={() => handleCartProducts()}
                      className="redButton text-xl	 h-14 mt-5	w-full addtoCart  bg-red hover:bg-darkred  text-white	hover:text-white"
                    >
                      {t("Cart")}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        {(selectedData || selectedTextData) && isMobile && (
          <button
            onClick={() => {
              setDisplay(false);
              setPopupContent(null);
              setSelectedData(null);
              setSelectedTextData(null);
            }}
            id="capturePointer"
            className="closeButton absolute right-5 top-4 text-4xl"
          >
            ×
          </button>
        )}
        {selectedData && isMobile && (
          <>
            <div className="absolute bottom-0 left-0 w-full h-auto inline-block">
              <div className="price bg-lightgrey w-1/2 float-left	mt-5	text-xl py-2 text-center align-middle table-cell">
                {language === "en-GB" && <span>£</span>}
                {selectedData.price[language] ||
                  selectedData.price[fallbackLanguage]}
                {language === "fr-FR" && <span>€</span>}
                {language === "en-FR" && <span>€</span>}
                {language === "en" && <span>€</span>}
                {language !== "fr-FR" &&
                  language !== "en" &&
                  language !== "en-GB" && <span>€</span>}
              </div>
              <button
                onClick={() => handleCartProducts()}
                className="redButton  float-left	  w-1/2 text-xl  mt-5	 py-2 addtoCart  bg-red hover:bg-darkred  text-white	hover:text-white"
              >
                {t("Cart")}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
