import React, { useState, memo, useRef, useEffect } from "react";
import usePopupContent from "./../Store/Popup";
import { isMobile } from "react-device-detect";
import useIntro from "./../Store/Intro";
import { useTranslation } from "react-i18next";

export const ScrollMessage = memo(function (props) {
  const [showScrollMessage, setScrollMessage] = useState(false);
  const [endingIntro, setEndingIntro] = useState(false);
  const [oldPopup, setOldPopupContent] = useState(null);
  const scrollTextRef = useRef();

  const [introDisplay, tutoDisplay] = useIntro((state) => [
    state.introDisplay,
    state.tutoDisplay,
  ]);
  const { t } = useTranslation();

  const [popupContent] = usePopupContent((state) => [state.popupContent]);

  let timingScroll;

  useEffect(() => {
    /* MOBILE SWIPE EVENTS */
    if (isMobile) {
      document
        .getElementById("capturePointer")
        .addEventListener("touchstart", moving, false);
    } else {
      /* MOUSE EVENTS */
      document
        .getElementById("root")
        .addEventListener("mousedown", moving, false);
      document
        .getElementById("capturePointer")
        .addEventListener("mousedown", moving, false);
      document
        .getElementById("capturePointer")
        .addEventListener("mouseup", moving, false);
      document.addEventListener("mousewheel", moving, false);
    }
    if (!introDisplay && !endingIntro) {
      setEndingIntro(true);
      moving();
    }
    if (popupContent == null && oldPopup !== popupContent) {
      moving();
    }
    if (oldPopup !== popupContent) {
      setOldPopupContent(popupContent);
    }

    if (scrollTextRef.current && !popupContent && !introDisplay) {
      if (showScrollMessage) {
        scrollTextRef.current.className = "fade-in-animation";
      } else {
        scrollTextRef.current.className = "hide-animation";
      }
    }
  }, [showScrollMessage, popupContent, introDisplay, endingIntro, oldPopup]);

  function moving() {
    setScrollMessage(false);
    clearTimeout(timingScroll);
    timingScroll = setTimeout(() => {
      setScrollMessage(true);
    }, "10000");
  }

  return (
    <>
      {showScrollMessage && !tutoDisplay && (
        <div ref={scrollTextRef}>
          <div className="absolute top-1/3 sm:top-2/3 left-1/2  transform -translate-x-1/2  z-10 text-white font-semibold  pointer-events-none	 flex items-center	 flex-wrap flex-row p-10">
            {!isMobile && (
              <>
                <div className="w-72 h-52 flex items-center flex-wrap flex-col justify-items-center">
                  <div className="blockImage w-20	">
                    <img src="./images/intro/scroll_3.gif" alt="scroll" />
                  </div>
                  <div className="p-5 text-center grow ">
                    <p>Scroll to continue</p>
                  </div>
                </div>
                <div className="w-72 h-52 flex items-center flex-wrap flex-col justify-items-center">
                  <div className="blockImage w-20	">
                    <img src="./images/intro/info-desktop.gif" alt="scroll" />
                  </div>
                  <div className="p-5 text-center grow ">
                    <p>{t("clickHotstop")}</p>
                  </div>
                </div>
              </>
            )}
            {isMobile && (
              <>
                <img
                  className="w-20  m-auto	"
                  src="./images/intro/scroll.gif"
                  alt="scroll"
                />
                <div className="text-center w-48	 	">Swipe to continue</div>

                <img
                  className="w-20  m-auto pt-10	"
                  src="./images/intro/info-mobile.gif"
                  alt="rotate camera"
                />
                <div className="text-center w-48	 	">{t("clickHotstop")}</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
});
