import { create } from "zustand";

const useIntro = create((set) => ({
  cookieDisplay: true,
  setCookieDisplay: (display) => set({ cookieDisplay: display }),
  introDisplay: true,
  setIntroDisplay: (intro) => set({ introDisplay: intro }),
  enterExperience: false,
  setEnterExperience: (enter) => set({ enterExperience: enter }),
  tutoDisplay: false,
  setTutoDisplay: (tuto) => set({ tutoDisplay: tuto }),
  playvideo: false,
  setPlayVideo: (play) => set({ playvideo: play }),
  lastclicked: false,
  setLastClicked: (click) => set({ lastclicked: click }),
  loadingFinished: false,
  setLoadingFinished: (status) => set({ loadingFinished: status }),
}));

export default useIntro;
