import React, { useRef, useState } from "react";
import { useGLTF, useCursor, Image } from "@react-three/drei";
import usePopupContent from "../../Store/Popup";

export function PodiumT(props) {
  const { nodes, materials } = useGLTF("./models/podium.glb");
  const [setPopupContent] = usePopupContent((state) => [state.setPopupContent]);
  const [hovered, setHovered] = useState(null);
  useCursor(hovered);

  /*
  sustainability_build
  sustainability_recycled
  sustainability_global
  */
  var showPopup = function (element) {
    setPopupContent(element);
    //   props.detectClick();
  };

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <group
          name="podiumT"
          position={[-20.5, 14.37, 52.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[1.67, 1.67, 2.33]}
        >
          <mesh
            name="Cube033"
            castShadow
            receiveShadow
            geometry={nodes.Cube033.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="Cube033_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_1.geometry}
            material={materials.Light_01}
          />
        </group>
        <group name="cubesRSE" scale={0.7}>
          <mesh
            name="Cube022"
            castShadow
            receiveShadow
            geometry={nodes.Cube022.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="Cube022_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube022_1.geometry}
            material={materials.RSE_RMI}
            //        onPointerUp={showPopup("sustainability_recycled")}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(1))}
            onPointerOut={(e) => setHovered(null)}
            onClick={(e) => showPopup("sustainability_recycled")}
          />
          <Image
            url={
              hovered === 1
                ? "./images/icons/hotspot_on_gris.png"
                : "./images/icons/hotspot_off_gris.png"
            }
            position={[-35, 22.2, 86]}
            rotation={[0, -Math.PI / 0.85, 0]}
            scale={0.5}
            transparent
            onPointerOver={(e) => (e.stopPropagation(), setHovered(1))}
            onPointerOut={(e) => setHovered(null)}
            onClick={(e) => showPopup("sustainability_recycled")}
          />
          <mesh
            name="Cube022_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube022_2.geometry}
            material={materials.RSE_BTL}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(2))}
            onPointerOut={(e) => setHovered(null)}
            onClick={(e) => showPopup("sustainability_build")}
          />
          <Image
            url={
              hovered === 2
                ? "./images/icons/hotspot_on_gris.png"
                : "./images/icons/hotspot_off_gris.png"
            }
            position={[-15, 22.5, 76]}
            rotation={[0, -Math.PI / 1.4, 0]}
            scale={0.5}
            transparent
            onPointerOver={(e) => (e.stopPropagation(), setHovered(2))}
            onPointerOut={(e) => setHovered(null)}
            onClick={(e) => showPopup("sustainability_build")}
          />
          <mesh
            name="Cube022_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube022_3.geometry}
            material={materials.RSE_GRN}
            //onPointerUp={showPopup("sustainability_global")}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(3))}
            onPointerOut={(e) => setHovered(null)}
            onClick={(e) => showPopup("sustainability_global")}
          />
          <Image
            url={
              hovered === 3
                ? "./images/icons/hotspot_on_gris.png"
                : "./images/icons/hotspot_off_gris.png"
            }
            position={[-36, 22.4, 81]}
            rotation={[0, -Math.PI / 0.78, 0]}
            transparent
            scale={0.5}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(3))}
            onPointerOut={(e) => setHovered(null)}
            onClick={(e) => showPopup("sustainability_global")}
          />
        </group>
        <group name="cubesRSENoir" scale={0.7}>
          <mesh
            name="Cube034"
            castShadow
            receiveShadow
            geometry={nodes.Cube034.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="Cube034_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube034_1.geometry}
            material={materials.RSE_BlackBox}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/podium.glb");
