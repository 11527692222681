/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function PodiumRSE3(props) {
  const { nodes, materials } = useGLTF("/models/podium_RSE3.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-14.54, 15.41, 59.04]} rotation={[0, -0.87, 0]}>
        <mesh
          geometry={nodes.Cube074.geometry}
          material={materials["Material.021"]}
        />
        <mesh
          geometry={nodes.Cube074_1.geometry}
          material={materials["Light_01.007"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/podium_RSE3.glb");
