import React, { useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useLoader, useFrame } from "@react-three/fiber";
import ListScreen from "./ListScreen";
import { isMobile } from "react-device-detect";

export function ScreensMobile(props) {
  const { nodes } = useGLTF("./models/environment/combined/affiches.glb");

  /* 19D */
  const textureNinteen1 = useLoader(
    THREE.TextureLoader,
    ListScreen.ninteen[0].image
  );
  const textureNinteen2 = useLoader(
    THREE.TextureLoader,
    ListScreen.ninteen[1].image
  );
  const textureNinteen3 = useLoader(
    THREE.TextureLoader,
    ListScreen.ninteen[2].image
  );
  textureNinteen1.flipY = false;
  textureNinteen2.flipY = false;
  textureNinteen3.flipY = false;

  /* ALPHA */
  const textureAlpha1 = useLoader(
    THREE.TextureLoader,
    ListScreen.alpha[0].image
  );
  const textureAlpha3 = useLoader(
    THREE.TextureLoader,
    ListScreen.alpha[1].image
  );
  textureAlpha1.flipY = false;
  textureAlpha3.flipY = false;

  /* TEGRA */
  const textureTegra1 = useLoader(
    THREE.TextureLoader,
    ListScreen.tegra[0].image
  );
  const textureTegra2 = useLoader(
    THREE.TextureLoader,
    ListScreen.tegra[1].image
  );
  const textureTegra4 = useLoader(
    THREE.TextureLoader,
    ListScreen.tegra[2].image
  );
  textureTegra1.flipY = false;
  textureTegra2.flipY = false;
  textureTegra4.flipY = false;

  /* MC LAREN */

  const textureMcL1 = useLoader(
    THREE.TextureLoader,
    ListScreen.mclaren[0].image
  );
  const textureMcL2 = useLoader(
    THREE.TextureLoader,
    ListScreen.mclaren[1].image
  );
  const textureMcL3 = useLoader(
    THREE.TextureLoader,
    ListScreen.mclaren[2].image
  );
  const textureMcL4 = useLoader(
    THREE.TextureLoader,
    ListScreen.mclaren[3].image
  );
  textureMcL1.flipY = false;
  textureMcL2.flipY = false;
  textureMcL3.flipY = false;
  textureMcL4.flipY = false;

  /* SUSTANABLE NO READY */
  const textureRse1 = useLoader(THREE.TextureLoader, ListScreen.rse[0].image);
  const textureRse2 = useLoader(THREE.TextureLoader, ListScreen.rse[1].image);
  textureRse1.flipY = false;
  textureRse2.flipY = false;

  /* VIDEO REPLACING */
  const rseVideoReplace = useLoader(
    THREE.TextureLoader,
    ListScreen.videoReplace[0].image
  );

  const AlphaBravoVideoReplace = useLoader(
    THREE.TextureLoader,
    ListScreen.videoReplace[1].image
  );

  const NdegreeVideoReplace = useLoader(
    THREE.TextureLoader,
    ListScreen.videoReplace[2].image
  );

  const tegraVideoReplace = useLoader(
    THREE.TextureLoader,
    ListScreen.videoReplace[3].image
  );

  rseVideoReplace.flipY = false;
  AlphaBravoVideoReplace.flipY = false;
  NdegreeVideoReplace.flipY = false;
  tegraVideoReplace.flipY = false;

  return (
    <group {...props} dispose={null}>
      <group scale={0.7}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affiches19Degree_01.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
          position={[-0.1, 0, 0]}
        >
          <meshBasicMaterial attach="material" map={textureNinteen1} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affiches19Degree_02.geometry}
          position={[-61.86, 12.71, -56.91]}
          rotation={[0.02, 0.02, -1.57]}
          scale={9.8}
        >
          <meshBasicMaterial attach="material" map={textureNinteen2} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affiches19Degree_03.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
          position={[0.1, 0, 0]}
        >
          <meshBasicMaterial attach="material" map={textureNinteen3} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affiches19Degree_04.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
          position={[0.1, 0, 0]}
        >
          <meshBasicMaterial attach="material" map={NdegreeVideoReplace} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesAlphaBravo_01.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          position={[-29.21, 18.82, -13.74]}
          scale={9.8}
        >
          <meshBasicMaterial attach="material" map={textureAlpha1} />
        </mesh>
        <group position={[-5.11, -3.62, 25.96]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.affichesAlphaBravo_012.geometry}
            position={[-34.89, 9.08, -13.42]}
            rotation={[Math.PI / 2, Math.PI, 0]}
            scale={[7.53, 9.8, 7.53]}
          >
            <meshBasicMaterial attach="material" map={textureAlpha1} />
          </mesh>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesAlphaBravo_02video.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
          position={[0.1, 0, 0]}
        >
          <meshBasicMaterial attach="material" map={AlphaBravoVideoReplace} />
        </mesh>
        <mesh
          name="affichesAlphaBravo_03video"
          castShadow
          receiveShadow
          geometry={nodes.affichesAlphaBravo_03.geometry}
          material={nodes.affichesAlphaBravo_03.material}
          position={[-27.36, 0.25, -14.32]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[5.86, 9.8, 5.86]}
        >
          <meshBasicMaterial attach="material" map={textureAlpha3} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesMcLaren_01.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        >
          <meshBasicMaterial attach="material" map={textureMcL1} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesMcLaren_02.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        >
          <meshBasicMaterial attach="material" map={textureMcL2} />
        </mesh>
        <group position={[47.36, 10.1, -38.32]} scale={1.43}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.affichesMcLaren03.geometry}
            position={[-35.9, -13.56, 29.68]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[7.36, 6.86, 8.9]}
          >
            <meshBasicMaterial attach="material" map={textureMcL3} />
          </mesh>
        </group>
        <group position={[47.36, 10.1, -38.32]} scale={1.49}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.haffichesMcLaren04.geometry}
            position={[-157.33, -20.38, 35.78]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[25.37, 6.86, 22.77]}
          >
            <meshBasicMaterial attach="material" map={textureMcL4} />
          </mesh>
        </group>

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesSustainable_01.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        >
          <meshBasicMaterial attach="material" map={textureRse1} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesSustainable_02.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
        >
          <meshBasicMaterial attach="material" map={textureRse2} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesTegra_01.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.8}
          position={[0.1, 0, 0]}
        >
          <meshBasicMaterial attach="material" map={textureTegra1} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesTegra_02.geometry}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={9.79}
        >
          <meshBasicMaterial attach="material" map={textureTegra2} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesTegra_video.geometry}
          position={[-42.02, 3.51, 45.5]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[7.28, 9.8, 8.86]}
        >
          <meshBasicMaterial attach="material" map={tegraVideoReplace} />
        </mesh>
        <group position={[-0.85, -0.2, -2.19]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.affichesTegra_04.geometry}
            material={nodes.affichesTegra_04.material}
            position={[-30.53, 10.55, 25.93]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[9.8, 3.69, 3.98]}
          >
            <meshBasicMaterial attach="material" map={textureTegra4} />
          </mesh>
        </group>
      </group>

      <group position={[6.15, -0.66, 23.55]} scale={0.7}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.affichesSustainable_video_rse.geometry}
          material={nodes.affichesSustainable_video_rse.material}
          position={[-33.85, 27.82, 54.97]}
          rotation={[-Math.PI / 2, 0, 1.57]}
          scale={[9.8, 15.02, 13.54]}
        >
          <meshBasicMaterial attach="material" map={rseVideoReplace} />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/combined/affiches.glb");
